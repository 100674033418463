<template>
  <aside ref="sidebar" class="sidebar">
    <div class="sidebar__inner">
      <slot></slot>
    </div>
  </aside>
</template>

<script>
import StickySidebar from "sticky-sidebar"
export default {
  name: "VStickySidebar",
  props: {
    sticky: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.sticky) {
      let topSpacing = 0;
      const $header = document.getElementById('header');
  
      if ($header)
        topSpacing = $header.offsetHeight;
    
      new StickySidebar(this.$refs['sidebar'], {
        innerWrapperSelector: '.sidebar__inner',
        topSpacing: topSpacing,
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  will-change: min-height;
}

.sidebar__inner {
  transform: translate(0, 0);
  /* For browsers don't support translate3d. */
  transform: translate3d(0, 0, 0);
  will-change: position, transform;
}
</style>