<template>
  <div
    v-if="orientation == 'top'"
    :class="{ 'opacity-0': !displayed }"
    :style="primaryStyle"
  >
    <template v-for="(item, index) in slidesData">
      <div
        :key="`slide-${index}`"
        :style="stylesSlide[index]"
      >
        <section
          class="slides-promo-flexible second-card"
          :class="item.class"
          :ref="`slide-${index}`"
          :style="[ maxHeight == 0 ? '' : `height: ${maxHeight};`]"
        >
          <div v-if="item.image.src.length > 0" :class="classForClassImage">
            <figure class="image">
              <img loading="lazy" :src="item.image.src" :alt="item.image.alt" :ref="`image-slide-${ index }`" />
            </figure>
          </div>
          <div class="second-card-content" :style="`height: ${maxHeight};`">
            <div
              v-if="item.image.credit && item.image.credit.length > 0"
              class="has-text-left"
            >
              <span class="made-by">Photo: <a href="#">John Smith/AFP</a></span>
            </div>
            <template v-if="item.logo">
              <div
                v-if="item.logo.src.length > 0"
                class="partner-logo has-text-right"
              >
                <img loading="lazy" :src="item.logo.src" :alt="item.logo.alt" />
              </div>
            </template>
            <div class="content">
              <div v-if="item.title" v-html="item.title" />
              <div v-html="item.htmlContent" style="hyphens: none" />
              <template v-if="item.link.hasLink">
                <a
                  v-if="item.link.external"
                  :href="item.link.link"
                  target="blank"
                  class="link-more link-external mt-3"
                  :class="[
                    item.changeContrast ? 'has-text-white' : 'has-text-link',
                  ]"
                >
                  {{ item.link.text }}
                </a>
                <a
                  v-else
                  :href="item.link.link"
                  class="link-more mt-3"
                  :class="[
                    item.changeContrast ? 'has-text-white' : 'has-text-link',
                  ]"
                >
                  {{ item.link.text }}
                </a>
              </template>
            </div>
            <div class="controls-for-slide top-orientation" :class="checkHelperTopMargin(!item.link.hasLink)">
              <div v-if="slidesData.length > 1" class="is-pulled-right">
                <template v-for="(itemButton, indexButton) in slidesData">
                  <button
                    v-if="itemButton.changeContrast"
                    :key="`control-${index}-${indexButton}`"
                    class="button-slide-high-contrast"
                    :class="stateControlButton(indexButton, true)"
                    @click="pauseAutoRotationAndChangeSlide(indexButton)"
                    title="Change slide"
                  ></button>
                  <button
                    v-else
                    :key="`control-${index}-${indexButton}`"
                    class="button-slide"
                    :class="stateControlButton(indexButton)"
                    @click="pauseAutoRotationAndChangeSlide(indexButton)"
                    title="Change slide"
                  ></button>
                </template>
              </div>
            </div>
          </div>
        </section>
      </div>
    </template>
  </div>
  <div v-else :class="{ 'opacity-0': !displayed }" :style="primaryStyle">
    <template v-for="(item, index) in slidesData">
      <div
        :key="`slide-${index}`"
        :style="stylesSlide[index]"
      >
        <section
          class="slides-promo-flexible"
          :class="`${horizontalSlideMainClass} ${
            item.class
          } ${checkTwoColumnsVariation(item)}`"
          :style="flexDirection"
          :ref="`slide-${index}`"
        >
          <div
            v-if="item.image.src.length > 0"
            class="second-card-image"
          >
            <figure class="image">
              <img loading="lazy" :src="item.image.src" :alt="item.image.alt" />
            </figure>
          </div>
          <div class="second-card-content content-promo" :style="`height: ${maxHeight};`">
            <div
              v-if="item.image.credit && item.image.credit.length > 0"
              class="pt-2 pb-2 has-text-left is-hidden-desktop"
            >
              <span class="made-by">Photo: <a href="#">John Smith/AFP</a></span>
            </div>
            <template v-if="item.logo">
              <div
                v-if="item.logo.src.length > 0"
                class="partner-logo has-text-right"
              >
                <img loading="lazy" :src="item.logo.src" :alt="item.logo.alt" />
              </div>
            </template>
            <div v-if="item.hasTwoColumns && item.htmlContent2">
              <div class="content">
                <div v-if="item.title" v-html="item.title" />
                <div class="columns">
                  <div class="column is-6-desktop" v-html="item.htmlContent" />
                  <div class="column is-6-desktop" v-html="item.htmlContent2" />
                </div>
                <template v-if="item.link.hasLink">
                  <a
                    v-if="item.link.external"
                    :href="item.link.link"
                    target="blank"
                    class="link-more link-external"
                    :class="[
                      item.changeContrast ? 'has-text-white' : 'has-text-link',
                    ]"
                  >
                    {{ item.link.text }}
                  </a>
                  <a
                    v-else
                    :href="item.link.link"
                    class="link-more"
                    :class="[
                      item.changeContrast ? 'has-text-white' : 'has-text-link',
                    ]">
                    {{ item.link.text }}
                  </a>
                </template>
              </div>
            </div>
            <div
              v-else
              class="content"
              style="hyphens: none"
            >
              <div v-if="item.title" v-html="item.title" />
              <div v-html="item.htmlContent" />
              <template v-if="item.link.hasLink">
                <a
                  v-if="item.link.external"
                  :href="item.link.link"
                  target="blank"
                  class="link-more link-external mt-3"
                  :class="[
                    item.changeContrast ? 'has-text-white' : 'has-text-link',
                  ]"
                >
                  {{ item.link.text }}
                </a>
                <a
                  v-else
                  :href="item.link.link"
                  class="link-more mt-3"
                  :class="[
                    item.changeContrast ? 'has-text-white' : 'has-text-link',
                  ]">
                  {{ item.link.text }}
                </a>
              </template>
            </div>
            <div
              v-if="item.image.credit && item.image.credit.length > 0"
              class="pt-2 pb-2 has-text-left is-hidden-tablet is-hidden-mobile"
            >
              <span class="made-by">Photo: <a href="#">John Smith/AFP</a></span>
            </div>
            <div
              class="controls-for-slide horizontal-orientation"
              :class="[ orientation == 'left' ? 'to-left' : 'to-right' ]"
            >
              <div v-if="slidesData.length > 1" class="is-flex">
                <template v-for="(itemButton, indexButton) in slidesData">
                  <button
                    v-if="itemButton.changeContrast"
                    :key="`control-${index}-${indexButton}`"
                    class="button-slide-high-contrast"
                    :class="stateControlButton(indexButton, true)"
                    @click="pauseAutoRotationAndChangeSlide(indexButton)"
                    title="Change slide"
                  ></button>
                  <button
                    v-else
                    :key="`control-${index}-${indexButton}`"
                    class="button-slide"
                    :class="stateControlButton(indexButton)"
                    @click="pauseAutoRotationAndChangeSlide(indexButton)"
                    title="Change slide"
                  ></button>
                </template>
              </div>
            </div>
          </div>
        </section>
      </div>
    </template>
  </div>
</template>

<script>
import { EventBus } from "../../EventBus";

export default {
  name: "SlidePromoFlexible",
  props: {
    slidesData: {
      type: Array,
      required: true,
    },
    orientation: {
      type: String,
      required: true,
    },
    featureBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    delayRotation: {
      type: Number,
      required: false,
      default: 7000,
    },
  },
  data() {
    return {
      displayed: false,
      slideIndex: 0,
      stylesSlide: [],
      classForClassImage: "second-card-image",
      pauseAutoRotation: false,
      maxHeight: "",
      theColor: "",
      helperTopMargin: false
    };
  },
  beforeCreate() {
    EventBus.$emit("domIsLoaded");
  },
  created() {
    this.setupSlideStyle();
    window.addEventListener("resize", this.setupOnResize);
  },
  mounted() {
    setTimeout(() => {
      this.calculateHeightOfSlide();
    }, 1000);

    // if (this.slidesData.length > 1) {
    //   setInterval(() => {
    //     this.autoRotationSlides();
    //   }, this.delayRotation);
    // }
    // else {
    //   this.autoRotationSlides();
    // }
  },
  destroyed() {
    window.removeEventListener("resize", this.setupOnResize);
  },
  computed: {
    flexDirection() {
      const flexDirectionValue = (this.orientation === 'left') ?
        'flex-direction: row;' :
        `flex-direction: row-reverse;`;
      return `${ flexDirectionValue }`;
    },
    horizontalSlideMainClass() {
      return this.featureBorder ? "second-card second-card-hero promo-flexible" : "second-card is-horizontal";
    },
    primaryStyle() {
      return this.slidesData.length == 1 ? { height: "100%" } : {};
    }
  },
  methods: {
    setupOnResize() {
      this.setupBorders(this.orientation);
    },
    setupBorders(orientation){
      const mediaQuery = window.matchMedia('(max-width: 768px)');
      if ((orientation === 'left' || orientation === 'right') && mediaQuery.matches) {
        return `border-top-left-radius: 15px;
          border-bottom-left-radius: 0px;
          border-top-right-radius: 15px;
          border-bottom-right-radius: 0px;`;
      }
      else if (orientation === 'left') {
        return `border-top-left-radius: 15px;
          border-bottom-left-radius: 15px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;`;
      }
      else {
        return `border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;`;
      }
    },
    pauseAutoRotationAndChangeSlide(newIndex) {
      this.pauseAutoRotation = true;
      this.changeSlide(newIndex);
    },
    changeSlide(newIndex) {
      this.slideIndex = parseInt(newIndex);
      this.setupSlideStyle();
    },
    setupSlideStyle() {
      this.stylesSlide = [];
      this.displayed = true;
      if (this.slidesData.length == 1) {
        this.stylesSlide = [{
          display: "block",
          height: "100%"
        }];
        return;
      }

      this.slidesData.forEach((slide, index) => {
        let styleToAdd;
        styleToAdd =
          this.slideIndex === index
            ? { display: "block" }
            : { display: "none" };
        this.stylesSlide.push(styleToAdd);
      });
    },
    async autoRotationSlides() {
      const automaticIndex =
        this.slideIndex === this.slidesData.length - 1
          ? 0
          : this.slideIndex + 1;

      if (this.pauseAutoRotation) {
        await new Promise((r) => setTimeout(r, this.delayRotation));
      }
      this.changeSlide(automaticIndex);
      this.pauseAutoRotation = false;
    },
    stateControlButton(index, highContrast = false) {
      if (parseInt(this.slideIndex) === parseInt(index)) {
        return highContrast
          ? "active-button-slide-high-contrast"
          : "active-button-slide";
      }
    },
    calculateHeightOfSlide() {
      let height = 0;
      if (this.slidesData.length === 1) {
        return height;
      }

      this.slidesData.forEach((slide, index) => {
        const heightCurrentSlide = this.orientation === 'top' ?
          this.$refs[`slide-${ index }`][0].clientHeight - this.$refs[`image-slide-${ index }`][0].clientHeight :
          this.$refs[`slide-${index}`][0].clientHeight;
        if (Math.ceil(height) < Math.ceil(heightCurrentSlide)) {
          height = heightCurrentSlide;
        }
      });
      this.setupSlideStyle();
      height = this.orientation === "top" ? height + 10 : height + 5;
      this.maxHeight = `${height}px`;
    },
    checkTwoColumnsVariation(item) {
      return (item.hasTwoColumns) ? 'is-full-width' : '';
    },
    checkHelperTopMargin(activeFlag) {
      if (!this.helperTopMargin && activeFlag) {
        this.helperTopMargin = true;
      }

      return this.helperTopMargin ? 'mt-5' : '';
    }
  },
};
</script>
