<template>
  <div>
    <div class="columns iframe--document-embed has-background-grey-lighter mx-1" :style="spinnerStyle" v-if="!loaded && !failedLoad">
      <spinner :class="'column is-flex is-justify-content-center is-align-items-center'" style="height: 100%" />
    </div>

    <article class="message is-info mt-5" v-if="failedLoad">
      <div class="message-body">
        Sorry, the document was not loaded correctly.
        <br>
        <button class="button is-primary is-dark has-text-white mt-3" @click="tryAgain">Try again</button>
      </div>
    </article>

    <iframe
      class="iframe--document-embed"
      :height="height"
      :width="width"
      frameborder="0"
      :title="title"
      ref="iframeEl"
      v-show="loaded"
    />
  </div>
</template>

<script>
export default {
  name: "DocumentEmbed",
  props: ["url", "title", "height", "width"],
  data() {
    return {
      maxTrys: 7,
      trys: 7,
      reloadSeconds: 10,
      loaded: false,
      iframeTimeoutId: null
    };
  },
  computed: {
    getIframeSrc() {
      return `https://docs.google.com/gview?url=${this.url}&embedded=true`;
    },
    spinnerStyle() {
      return { height: this.height + 'px' }
    },
    failedLoad() {
      return this.trys === 0 && !this.loaded;
    }
  },
  methods: {
    loadIframe() {
      this.loaded = true;
      this.stopTimer();
    },
    reloadIframe() {
      this.loaded = false;
      if (this.trys > 0) {
        console.log('reload');
        this.$refs.iframeEl.src = this.getIframeSrc;
        this.trys--;
        return;
      }
      this.stopTimer();
    },
    tryAgain() {
      this.trys = this.maxTrys;
      this.reloadIframe();
      this.initTimer();
    },
    initTimer() {
      this.iframeTimeoutId = setInterval(
        this.reloadIframe,
        1000 * this.reloadSeconds
      );
    },
    stopTimer() {
      clearInterval(this.iframeTimeoutId);
      this.iframeTimeoutId = null;
    }
  },
  mounted() {
    this.$refs.iframeEl.addEventListener("load", this.loadIframe, false);
    this.$refs.iframeEl.addEventListener("error", this.reloadIframe, false);
    this.$refs.iframeEl.src = this.getIframeSrc;
    this.initTimer();
  },
};
</script>
