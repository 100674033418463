<template>
  <div
    class="
      card
      aside-card
      filter-card
      is-shadowless
      has-background-primary-lightest
    "
  >
    <div class="card-content">
      <div
        class="mt-3"
        v-for="facetIndex in Object.keys(facets).filter(
          (f) =>
            searchFilterOptions &&
            searchFilterOptions.options &&
            searchFilterOptions.options[f]
        )"
        :key="`filter-${facetIndex}`"
        :set="(facet = facets[facetIndex])"
      >
        <div class="aside-filter-form">
          <filter-block
            v-if="facet['showedOptions'].length > 0"
            :title="searchFilterOptions.options[facetIndex].label"
            :visible-options="facet['showedOptions']"
            :drop-down-options="facet['hiddenOptions']"
            :input-name="facetIndex"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "FacetsGeneralSearch",
  computed: {
    ...mapState({
      facets: (state) => state.search.searchInfo.facets,
      searchFilterOptions: (state) => state.search.searchFilterOptions,
    }),
  },
};
</script>
