<template>
  <vue-date-picker
  class="field-date-picker"
    no-header
    v-model="value"
    :format="format.toUpperCase()"
    clearable
    :placeholder="format.toUpperCase()"
    :locale="{ lang: 'en' }"
  />
</template>

<script>
export default {
  name: "VDatePicker",
  props: {
    format:{
      type: String,
      default: "MM/DD/YYYY"
    },
    id: {
      type: String,
      require: true
    },
    name: {
      type: String,
      require: true
    }
  },
  data(){
    return {
      value: null
    } 
  },
  mounted(){
    const $input = this.$el.querySelector("input");
    $input.name = this.name;
    $input.id = this.id;
  },
};
</script>
