<template>
  <div
    class="navbar-item has-dropdown is-hoverable"
    :class="{ 'is-active': isActive }"
    v-closable="{exclude: [uniqueId], handler: 'closeMenu'}"
    @mouseover="hoverActionItems(true)"
    @mouseleave="hoverActionItems(false)"
  >
    <a
      class="navbar-link is-arrowless"
      :class="{ 'is-active': isActive }"
      :ref="uniqueId"
      :href="link"
      @click="menuAction($event)"
    >
      {{ title }}
    </a>
    <slot />
  </div>
</template>

<script>
import { EventBus } from "../../EventBus";

export default {
  name: "MenuItem",
  props: {
    title: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      default: '#'
    },
    activeItem: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false,
      inactiveAllItems: false,
      previousHoverState: false
    };
  },
  methods: {
    closeMenu(){
      const checkIsDesktop = window.matchMedia('(min-width: 1000px)');
      if (!checkIsDesktop.matches) {
        this.isActive = false;
      }
    },
    menuAction(e){
      e.preventDefault();
      const mediaQuery = window.matchMedia('(max-width: 768px)');
      if (mediaQuery.matches) {
        this.isActive = !this.isActive;
      }
      else {
        location.href = this.link;
      }
    },
    hoverActionItems(state) {
      this.inactiveAllItems = state;
    }
  },
  watch: {
    isActive(value) {
      if (value) {
        EventBus.$emit("menuItemActive", this._uid);
      }
    },
    inactiveAllItems(value) {
      if (value) {
        EventBus.$emit("inactiveAll", this._uid);
      }
      else {
        EventBus.$emit("activePreviousState", this._uid);
      }
    }
  },
  mounted() {
    const mediaQuery = window.matchMedia('(min-width: 576px)');
    if (this.activeItem && mediaQuery.matches) {
      this.isActive = !this.isActive;
    }

    EventBus.$on("menuItemActive", (id) => {
      if (id != this._uid) {
        this.isActive = false;
      }
    });

    EventBus.$on("inactiveAll", (id) => {
      if (id != this._uid) {
        this.previousHoverState = this.isActive;
        this.isActive = false;
      }
    });

    EventBus.$on("activePreviousState", (id) => {
      if (id != this._uid) {
        this.isActive = this.previousHoverState;
      }
    });
  },
  computed: {
    uniqueId(){
      return `menu-${this._uid}`;
    }
  },
};
</script>
