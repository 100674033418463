<template>
  <section v-if="isLoading" class="skeleton aside-news aside-block block">
    <div class="content" id="content-listing">
      <div class="posts is-bordered-left mt-0">
        <section v-for="item in [1,2,3]" :key="item" class="card card-news is-horizontal is-small is-shadowless no-hover-shadow">
          <div class="card-content">
            <div class="content">
              <div class="meta">
                <time class="date"><span class="skeleton__item">Lorem ipsum</span></time>
              </div>
              <h5 class="has-text-info">
                <span class="skeleton__item">Lorem ipsum dolor sit ammet consectetur adispicing</span>
              </h5>
              <div class="tags">
                <a class="tag button is-primary is-outlined is-uppercase">
                  <span class="skeleton__item">Lorem</span>
                </a>
                <a class="tag button is-primary is-outlined is-uppercase">
                  <span class="skeleton__item">Lorem</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
  <div v-else class="aside-news aside-block block" @scroll="handleScroll">
    <div class="content" id="content-listing">
      <div class="posts is-bordered-left mt-0">
        <section
          class="card card-news is-horizontal is-small is-shadowless no-hover-shadow"
          v-for="article in searchInfo.data"
          :key="`article-${article.location_id}`"
          v-bind:class="{
            active: (selectedArticle != null && selectedArticle.location_id == article.location_id),
            'no-tags': article.tags.length === 0
          }"
        >
          <div class="card-content">
            <div class="content">
              <div class="meta">
                <span class="category-label is-uppercase has-text-primary">
                  <a href="#"></a>
                </span>
                <time class="date" :datetime="article.publish_date.ymd">{{ article.publish_date.mdy }}</time>
              </div>
              <h5 class="has-text-info">
                <a :href="article.url" @click="loadArticle($event, article)" v-html="article.title"></a>
              </h5>
              <div v-if="article.tags.length > 0" class="tags">
                <a
                  v-for="(tag, index) in article.tags"
                  :key="`tag-${index}`"
                  :href="tag.url"
                  class="tag button is-primary is-outlined is-uppercase"
                >{{ tag.name }}</a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SitePageResults",
  computed: {
    ...mapGetters({
      searchInfo: "getSearchInfo",
      isLoading: "getLoadingState",
      isLoadingNewPage: "getLoadingNewPageState",
      selectedArticle: "getSelectedArticle",
    }),
  },
  methods: {
    getWidthContainer() {
      const appWidth = document.getElementById('app');
      return appWidth.offsetWidth;
    },
    loadArticle(e, article) {
      e.preventDefault();

      this.$store.dispatch("assignSelectedArticle", article);
      this.$store.dispatch("fetchArticleContent", article.location_id);

      if(this.getWidthContainer() < 768) {
        document.getElementById('news-article').scrollIntoView({
          behavior: 'smooth'
        });
      }
    },
    handleScroll: function(e) {
      const currentScrollPosition = e.srcElement.scrollTop;
      const contentListing = window.document.getElementById('content-listing');

      const offsetHeight = contentListing.offsetHeight - 1500;

      if (!this.isLoadingNewPage && this.searchInfo.pagination.has_next && currentScrollPosition >= offsetHeight) {
        this.$store.dispatch('fetchNewPageSearch', this.searchInfo.pagination.next_page);
      }
    }
  }
};
</script>
