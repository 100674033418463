<template>
  <div class="news-articles mt-3">
    <article>
      <span class="search-result has-text-weight-medium has-text-dark">
        {{ pagination.total }}
        {{ pagination.total == 1 ? "Result" : "Results" }}
      </span>
    </article>

    <div class="columns is-multiline">
      <div v-for="(resource, index) in data" class="column is-full">
        <section class="card card-news is-horizontal is-shadowless px-0">
          <div class="card-content">
            <div class="content">
              <div class="meta">
                <span class="category-label is-uppercase has-text-primary">
                  <a :href="resource.url" v-html="resource.title"></a>
                </span>
              </div>
              <h5>
                <a :href="resource.url" v-html="resource.title"></a>
              </h5>
              <p v-html="resource.description"></p>
              <div class="tags" v-if="resource.tags">
                <a
                  v-for="(tag, index) in resource.tags"
                  :key="`tag-${index}`"
                  :href="tag.url"
                  class="tag button is-primary is-outlined is-uppercase"
                >
                  {{ tag.name }}
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div v-if="pagination.has_next" class="filter-block is-open-close-active">
      <div class="has-text-centered">
				<a href="#" class="load-more-link" @click="loadMoreResults($event)">
          <span class="hidden-text">Load More</span>
        </a>
			</div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: "ResearchResourcesResults",
  props: {
    resources:{ 
      type: Array,
      default: () => []
    },
    urlEndpoint: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      data: this.resources.data,
      pagination: this.resources.pagination,
    };
  },
  methods: {
    async loadMoreResults(e) {
      e.preventDefault();
      try {
        if (this.pagination.has_next) {
          const response = await axios.get(`${this.urlEndpoint}?page=${this.pagination.next_page}`);
          this.data.push(...response.data.data);
          this.pagination = response.data.pagination;
        }
      } catch (error) {
        console.error("Error fetching resources:", error);
      }
    }
  }
};
</script>