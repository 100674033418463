<template>
  <div class="second-search">
    <div class="second-search-btn">
      <button
        class="second-search-btn-open"
        aria-label="Open Search"
        @click="open = true"
      >
        <i v-icon:search></i>
      </button>
      <button
        class="second-search-btn-close"
        :class="{ open }"
        aria-label="Close Search"
        @click="open = false"
      >
        <i v-icon:xmark></i>
      </button>
    </div>
    <div class="second-search-wrapper" :class="{ open }">
      <div class="second-search-overlay"></div>
      <div class="container">
        <div class="second-search-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VSearchButton",
  data() {
    return {
      open: false,
    };
  },
  watch: {
    open(a) {
      document.querySelector("html").style.overflow = a ? "hidden" : ''
    }
  }
};
</script>

<style lang="scss" scoped></style>
