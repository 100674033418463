<template>
  <div :class="['navigator-highlight', styleClass, { 'is-second': isSecond }]">
    <div class="section-heading" v-if="!!title">
      <div v-html="title" />
    </div>
    <div v-if="columns === columnsFullWidth && chemistryList.length === 0">
      <navigation-product-full-width :product-list="productList" />
    </div>
    <div v-else class="columns is-multiline">
      <div v-if="chemistryList.length > 0" :class="classChemistryElements">
        <div class="content">
          <button-list-navigator
            :upper-class="classForChemistryIcons"
            :buttons="chemistryList"
            :is-second="isSecond"
          />
        </div>
      </div>
      <div v-if="productList.length > 0" :class="classListProducts">
        <ul class="has-text-info list-products">
          <li
            v-for="(item, index) in productList"
            :key="`item-product-${index}`"
            :class="[chemistryList.length == 0 ? 'ml-0' : '']"
          >
            <a :href="item.link" v-html="item.text" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationProductFullWidth from "./NavigationProductFullWidth.vue";

export default {
  name: "NavigationHighLight",
  components: {
    NavigationProductFullWidth,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    columns: {
      type: Number,
      default: 0,
    },
    chemistryList: {
      type: Array,
      required: true,
    },
    productList: {
      type: Array,
      required: true,
    },
    styleClass: {
      type: String,
      default: "",
    },
    isSecond: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columnsFullWidth: 3,
    };
  },
  computed: {
    classChemistryElements() {
      if (this.productList.length === 0) {
        return "column is-12";
      }

      switch (this.columns) {
        case 1:
          return "column is-5-desktop is-12-tablet";
        case 2:
          return "column is-6-widescreen is-12-desktop is-12-tablet";
        case 3:
          return "column is-6-widescreen is-12-desktop is-12-tablet";
        default: // half width
          return "column is-6-widescreen is-12-desktop is-12-tablet";
      }
    },
    classListProducts() {
      if (this.chemistryList === 0) {
        return "column is-12";
      }

      switch (this.columns) {
        case 1:
          return "column is-7-desktop  is-12-tablet";
        case 2:
          return "column is-6-widescreen is-12-desktop is-12-tablet";
        case 3:
          return "column is-6-widescreen is-12-desktop is-12-tablet";
        default: // half width
          return "column is-6-widescreen is-12-desktop is-12-tablet";
      }
    },
    classForChemistryIcons() {
      const baseClass = "buttons-list";
      switch (this.columns) {
        case 1:
          return `${baseClass} one-column-desktop is-small`;
        case 2:
          return `${baseClass} three-columns-desktop`;
        case 3:
          return `${baseClass} four-columns-desktop`;
        default: // half width
          return `${baseClass} two-columns-desktop`;
      }
    },
  },
};
</script>
