<template>
  <li>
    <div class="is-flex">
    <a :href="link" class="font-weight-600" :title="title">
      <i style="margin-left: -5px;" class="icon icon-chevron-right" />
    </a>
		<a :href="link" v-text="title" class="font-weight-600" :class="{ 'has-text-link' : active }" />
    </div>
	</li>
</template>

<script>
export default {
  name: 'CollapseMenuIcon',
  props: {
    title: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    iconClass : {
      type: String,
      default: 'icon icon-chevron-right'
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>
