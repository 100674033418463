import search from './search';

const storeData = {
  state: {
    sticky: []
  },
  mutations: {
    PUSH_STICKY: (state, value) => {
      state.sticky.push(value);
    }
  },
  modules: {
    search
  }
};

export let store = {};
const createStore = Vuex => {
  store = new Vuex.Store(storeData);
  return store;
};

export default createStore;
