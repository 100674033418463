<template>
  <div class="navbar-item has-dropdown" :class="{ 'is-active': itemIsActive }">
    <a
      href="#"
      class="navbar-link"
      @click="changeItemState($event)"
      :class="{ 'is-active': itemIsActive }"
    >
      {{ title }}
    </a>
    <div class="navbar-dropdown is-hidden-tablet">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "DropDownItem",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      itemIsActive: false,
    };
  },
  methods: {
    changeItemState(e) {
      e.preventDefault();
      this.itemIsActive = !this.itemIsActive;
    },
  },
};
</script>
