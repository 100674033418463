import Vue from "vue/dist/vue.esm";
import Vuex from "vuex";

import { IbexaPageBuilderBlockRefresher } from "@aplyca/ibexa-pagebuilder-block-refresher";

import VueYoutube from "vue-youtube";
import vueVimeoPlayer from "vue-vimeo-player";
import Vuelidate from "vuelidate";

import { VueDatePicker } from "@mathieustan/vue-datepicker";
import { EventBus } from "./EventBus";
import createStore from "./store";
import fixedMixin from "./utils/fixedMixin";
import UtilsPlugin from "./utils";

import "aos/dist/aos.css";
import "./utils/ResizeSensor.js"

import vSelect from "vue-select";
Vue.component("v-select", vSelect);

Vue.use(Vuex);
Vue.use(VueYoutube);
Vue.use(vueVimeoPlayer);
Vue.use(Vuelidate);
Vue.use(UtilsPlugin);

const files = require.context("./components/", true, /\.vue$/i);
files
  .keys()
  .map((key) =>
    Vue.component(key.split("/").pop().split(".")[0], files(key).default)
  );

Vue.component("vue-date-picker", VueDatePicker);

new Vue({
  el: "#app",
  store: createStore(Vuex),
  mixins: [fixedMixin],
  data() {
    return {
      isOpenAddMenu: false,
      toogleSearchForm: {
        touchScreen: false,
        desktop: false,
      },
      classForToogleSearchForm: {
        touchScreen: "",
        desktop: "",
      },
      isMountedComponent: false,
      toogleAboutAccDropDown: false,
      classForAboutAccDropdown: "",
      showSecondaryNavbar: false,
      checkBreakpointTouchScreen: "",
      showFloatingBox: true,
    };
  },
  beforeCreate() {
    EventBus.$on("domIsLoaded", () => {
      this.isMountedComponent = true;
    });
    this.$store.dispatch("assignInitiallizeStore");
  },
  mounted() {
    const $card = document.querySelector("#fixedCardContact");
    this.setFixedArea($card);

    IbexaPageBuilderBlockRefresher("4.6.*", function (blockElement) {
      new Vue({
        el: blockElement,
        store: createStore(Vuex),
        delimiters: ["${", "}"],
      });
    });
    document.addEventListener("copy", (event) => {
      if (document.getSelection().toString().length <= 50) {
        return;
      }

      const pagelink = `\n\nLearn more at: ${document.location.href}`;
      const selectedText = document.getSelection().toString();
      event.clipboardData.setData("text", `${selectedText} ${pagelink}`);
      event.preventDefault();

      if (window?.dataLayer) {
        window.dataLayer.push({
          event: "copy-text",
          textData: selectedText,
        });
      }
    });
    this.checkBreakpointTouchScreen = window.matchMedia("(max-width: 1023px)");
    this.setupFloatingBox();
    window.addEventListener("resize", this.setupFloatingBox);

    this.$store.state.sticky.forEach((item) => {
      const $sticky = item.querySelector("[data-sticky]");
      const $hash = $sticky.querySelectorAll("a[href^='#']");

      $hash.forEach((a) => {
        a.onclick = (e) => {
          e.preventDefault();
          const $element =
            document.querySelector(a.hash) ||
            document.querySelector(`a[name=${a.hash.replace("#", "")}]`);
          const $coord = $element.getBoundingClientRect();
          location.hash = a.hash;
          window.scrollBy(0, $coord.top - $sticky.scrollHeight);
        };
      });
    });

    window.onload = () => {
      this.sectionSticky();
      window.onscroll = () => {
        this.sectionSticky();
      };
    };

    // setTimeout(AOS.refresh, 400);
    this.headerSticky();
    const $logout = document.getElementById("Logout");
    if ($logout)
      document.querySelector('html').classList.add("login")
  },
  methods: {
    sectionSticky() {
      this.$store.state.sticky.forEach((item, index, arr) => {
        const $wrapper = item.querySelector("[data-wrapper]");
        const $sticky = item.querySelector("[data-sticky]");
        $wrapper.style.height = $sticky.scrollHeight + "px";

        const $coord = $wrapper.getBoundingClientRect();
        if (index) {
          const $before = arr[index - 1].querySelector("[data-sticky]");
          if ($coord.top - $before.scrollHeight < 152) {
            $before.style.top = `${$coord.top - $before.scrollHeight}px`;
            $wrapper.classList.add("section-sticky-span");
          } else {
            $before.style.top = 0;
            $wrapper.classList.remove("section-sticky-span");
          }
        }

        if ($coord.top < 152) {
          $wrapper.classList.add("section-sticky-wrapper");
          if (!index) $wrapper.classList.add("section-sticky-span");
        } else {
          $wrapper.classList.remove("section-sticky-wrapper");
          if (!index) $wrapper.classList.remove("section-sticky-span");
        }
      });
    },
    headerSticky() {
      const $class = "sticky-active";
      let currentScroll = 0;
      window.addEventListener("scroll", (e) => {
        const $header = document.getElementById("header");
        const $html = document.querySelector("html");
        if ($header) {
          if ($header.clientHeight < $html.scrollTop && $html.scrollTop > currentScroll) {
            $html.classList.add($class);
          } else if ($html.scrollTop < currentScroll) {
            $html.classList.remove($class);
          }
          currentScroll = $html.scrollTop;
        }
      });
    },
    toogleAddMenu(e) {
      e.preventDefault();
      this.isOpenAddMenu = !this.isOpenAddMenu;
    },
    openAndCloseSearchForm(e) {
      e.preventDefault();
      if (this.checkBreakpointTouchScreen.matches) {
        this.toogleSearchForm.touchScreen = !this.toogleSearchForm.touchScreen;
        this.classForToogleSearchForm.touchScreen = this.toogleSearchForm
          .touchScreen
          ? "is-active"
          : "is-hiding";
      } else {
        this.toogleSearchForm.desktop = !this.toogleSearchForm.desktop;
        this.classForToogleSearchForm.desktop = this.toogleSearchForm.desktop
          ? "is-active"
          : "is-hiding";
      }
    },
    closeSearchForm() {
      if (this.checkBreakpointTouchScreen.matches) {
        this.toogleSearchForm.touchScreen = false;
        this.classForToogleSearchForm.touchScreen = "is-hiding";
      } else {
        this.toogleSearchForm.desktop = false;
        this.classForToogleSearchForm.desktop = "is-hiding";
      }
    },
    closeSearchFormDesktop() {
      this.toogleSearchForm.desktop = false;
      this.classForToogleSearchForm.desktop = "is-hiding";
    },
    closeSearchFormTouchScreen() {
      this.toogleSearchForm.touchScreen = false;
      this.classForToogleSearchForm.touchScreen = "is-hiding";
    },
    closeAddMenu() {
      this.isOpenAddMenu = false;
    },
    openAndCloseAboutAccDropdown(e) {
      e.preventDefault();
      this.toogleAboutAccDropDown = !this.toogleAboutAccDropDown;
      this.classForAboutAccDropdown = this.toogleAboutAccDropDown
        ? "is-active"
        : "is-hiding";
    },
    closeAboutAccDropDown() {
      if (this.toogleAboutAccDropDown) {
        this.toogleAboutAccDropDown = false;
        this.classForAboutAccDropdown = "is-hidden";
      }
    },
    searchIsDone(e) {
      this.$store.dispatch("assignSearchByTermDone");
    },
    toogleSecondaryNavbar(e) {
      if (!this.showSecondaryNavbar) {
        e.preventDefault();
        this.showSecondaryNavbar = true;
      }
    },
    closeContactBox(e) {
      e.preventDefault();
      this.showFloatingBox = !this.showFloatingBox;
    },
    setupFloatingBox() {
      const isMobile = window.matchMedia("(max-width: 768px)");
      this.showFloatingBox = !isMobile.matches;
    },
  },
});
