<template>
  <section class="section">
    <div class="container">
      <div class="heading-row">
        <div class="columns is-multiline is-align-items-flex-end">
          <div class="column is-12 is-4-fullhd pb-0">
            <h1 v-html="searchDefinitions.title"></h1>
          </div>
          <div v-if="searchFilters.keyword.length" class="column is-12 is-8-fullhd pb-0">
            <div class="search-title">
              <h4>“{{ searchFilters.keyword }}”</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <aside v-if="Object.keys(facets).length > 0" class="aside-column column is-4-tablet">
          <div class="filter-btn-holder">
            <a href="#" @click="toggleFilterMobile($event)" class="filter-opener">Filter results</a>
          </div>
          <div :class="[ filterIsSelected ? 'dropdown-animation': 'is-hidden-mobile' ]" class="card aside-card filter-card is-shadowless has-background-primary-lightest">
            <facets-general-search />
          </div>
        </aside>
        <div class="content-column column">
          <filters-general-search />
          <general-search-results />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "GeneralSearch",
  props: {
    apiUrl: {
      type: String,
      required: true
    },
    results: {
      type: Object,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
    searchDefinitions: {
      type: Object,
      required: true
    },
    filterOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filterIsSelected: true
    }
  },
  methods: {
    toggleFilterMobile(e) {
      e.preventDefault();
      this.filterIsSelected = !this.filterIsSelected;
    }
  },
  computed: {
    ...mapGetters({
      searchInfo: "getSearchInfo",
      searchFilters: "getSearchFilters"
    }),
    ...mapState({
      facets: (state) => state.search.searchInfo.facets,
    }),
  },
  created() {
    console.log(`reviso esta vaina`);
    console.log(`resultado del getter: ${ this.$store.getters.getSearchByTermDone }`)
    //this.filterIsSelected = false;
    if (this.$store.getters.getSearchByTermDone && window.matchMedia('(max-width: 768px)').matches) {
      this.filterIsSelected = false;
    }
  },
  mounted() {
    const formattedFacets = {};

    for (let filterIndex in this.results.facets) {
      formattedFacets[filterIndex] = {
        showedOptions: this.results.facets[filterIndex].options.slice(0, 3),
        hiddenOptions: this.results.facets[filterIndex].options.slice(3)
      }
    }

    this.$store.dispatch("assignApiUrl", this.apiUrl);
    this.$store.dispatch("assignGeneralSearchInfo", { ...this.results, facets: formattedFacets });
    this.$store.dispatch("assignGeneralSearchFilters", this.filters);
    this.$store.dispatch("assignSearchDefinitions", this.searchDefinitions);
    this.$store.dispatch("assignSearchFilterOptions", this.filterOptions);
  },
};
</script>
