<template>
  <div
    class="card search-sort-card is-shadowless has-background-primary-lightest"
  >
    <div class="card-content">
      <form class="search-sort-form" action="#">
        <div class="columns is-variable is-1">
          <div class="column">
            <div class="search-form">
              <div class="control">
                <label class="label" for="search-terms-3">{{
                  searchDefinitions.search_term_label.length > 0
                    ? searchDefinitions.search_term_label
                    : "Search keyword"
                }}</label>
                <input
                  type="text"
                  class="text"
                  id="search-terms-3"
                  placeholder="Keyword search"
                  :value="filters.keyword"
                  v-on:keyup="updateSearchKeyword($event)"
                />
                <button
                  @click="callSearch($event)"
                  type="submit"
                  class="btn-search"
                >
                  <i class="icon icon-search"></i>
                  <span class="is-sr-only">{{
                    searchDefinitions.search_term_label.length > 0
                      ? searchDefinitions.search_term_label
                      : "Search keyword"
                  }}</span>
                </button>
              </div>
            </div>
          </div>
          <div
            class="column is-4-tablet is-3-desktop"
            v-if="
              searchDefinitions.sorting_options &&
              searchDefinitions.sorting_options.length > 0
            "
          >
            <div class="control">
              <label class="label" for="sort-3">Sort</label>
              <div class="select">
                <custom-select
                  name="sort"
                  placeholder="Select Sort"
                  :options="sortingOptions"
                  @change="updateSearchSort"
                ></custom-select>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "../../../EventBus";

export default {
  name: "FiltersGeneralSearch",
  data() {
    return {
      keyword: "",
    };
  },
  computed: {
    ...mapGetters({
      filters: "getSearchFilters",
      searchDefinitions: "getSearchDefinitions",
    }),
    sortingOptions() {
      const res = [];
      this.searchDefinitions.sorting_options.forEach(({ name, value }) => {
        res.push({ name: `${name} (des)`, value: `${value}-des` });
        res.push({ name: `${name} (asc)`, value: `${value}-asc` });
      });
      return res;
    },
  },
  methods: {
    callSearch(e) {
      e.preventDefault();
      this.$store.dispatch('assignSearchByTermDone');

      this.$store.dispatch("assignGeneralSearchFilters", {
        keyword: this.keyword,
      });
      this.$store.dispatch("fetchGeneralSearch");
    },
    updateSearchKeyword(e) {
      e.preventDefault();
      this.keyword = e.target.value;

      if (e.keyCode === 13) {
        this.callSearch(e);
      }
    },
    updateSearchSort(e) {
      const sort = {};
      const [field, direction] = e.split("-");

      sort[field] = direction;

      this.$store.dispatch("assignGeneralSearchFilters", { sort });
      this.$store.dispatch("fetchGeneralSearch");
    },
  },
  mounted() {
    this.keyword = this.filters.keyword;
  },
};
</script>
