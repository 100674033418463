<template>
  <div class="filter-news">
    <div class="section-heading filter-heading">
      <h3 class="is-hidden-tablet is-hidden-mobile">{{ title }}</h3>
      <div class="filter-btn-holder">
        <a
          href="#"
          class="filter-opener"
          :class="{ 'is-active': expanded }"
          @click="toggleFilter($event)"
        >
          {{ filterTag }}
        </a>
      </div>
    </div>
    <div :class="[ expanded ? 'dropdown-animation': 'is-hidden' ]" >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterNews",
  props: {
    title: {
      type: String,
      required: true
    },
    filterTag: {
      type: String,
      required: true
    },
    expanded: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      filterIsSelected: this.expanded,
    };
  },
  methods: {
    toggleFilter(e){
      e.preventDefault();
      this.expanded = !this.expanded;
    }
  },
};
</script>
