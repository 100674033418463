<template>
  <div class="accordion-item" :class="{ 'is-active' : acordionIsOpen }" >
		<h6>
      <a class="opener" href="#" @click="toggleAcordion($event)">
      <span class="accordion-title">{{ header }}</span>
      <span class="icon icon-transparent-fill" :class="{ 'rotate-icon-variant' : acordionIsOpen }"  v-icon:head-arrow></span>
      </a>
    </h6>
		<div class="slide">
			<div class="content pb-5" :class="{ 'is-hidden' : !acordionIsOpen }">
				<slot />
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'AcordionItem',
  props: {
    header: {
      type: String,
      required: true
    },
    expanded: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      acordionIsOpen: false,
    }
  },
  mounted() {
    this.acordionIsOpen = this.expanded ?? false;

  },
  methods: {
    toggleAcordion(e){
      e.preventDefault();
      this.acordionIsOpen = !this.acordionIsOpen;
    }
  },

}
</script>
