<template>
  <div class="box is-shadowless has-background-primary-lightest mb-3">
    <form class="search-form">
      <label class="label" for="search-keyword">{{ searchDefinitions.search_term_label && searchDefinitions.search_term_label.length > 0 ? searchDefinitions.search_term_label : 'Keyword Search' }}</label>
      <div class="control">
        <input
          type="search"
          class="input"
          id="search-keyword"
          placeholder="Search"
          :value="filters.keyword"
          v-on:keyup="updateSearchKeyword($event)"
        />
        <button @click="callSearch($event)" type="submit" class="btn-search">
          <i class="icon icon-search"></i><span class="is-sr-only">Search</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SitePageKeyword",
  data() {
    return {
      keyword: ''
    }
  },
  computed: {
    ...mapGetters({
      filters: "getSearchFilters",
      searchDefinitions: "getSearchDefinitions",
    }),
  },
  methods: {
    callSearch(e) {
      e.preventDefault();

      this.$store.dispatch('assignGeneralSearchFilters', { keyword: this.keyword });
      this.$store.dispatch("fetchGeneralSearch");
    },
    updateSearchKeyword(e) {
      e.preventDefault();
      this.keyword = e.target.value;

      if (e.keyCode === 13) {
        this.callSearch(e);
      }
    }
  },
  mounted() {
    this.keyword = this.filters.keyword;
  }
};
</script>
