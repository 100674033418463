<template>
  <skeleton id="news-article" v-if="isLoadingArticle || !compiledHtml"></skeleton>
  <component :is="compiledHtml" v-else-if="!!compiledHtml"></component>
</template>

<script>
import Vue from 'vue/dist/vue.esm';
import { mapGetters } from "vuex";

export default {
  name: "SitePageArticle",
  data() {
    return {
      compiledHtml: null
    }
  },
  computed: {
    ...mapGetters({
      isLoadingArticle: "getLoadingArticleState",
      articleHtml: "getArticleHtml"
    }),
  },
  watch: {
    articleHtml(newValue) {
      if (!!newValue) {
        this.compiledHtml = Vue.compile(`<div id="news-article">${newValue}</div>`);
      }
    }
  },
};
</script>
