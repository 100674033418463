<template>
  <div class="secondary-navbar">
    <ul class="list-unstyled has-text-blue">
      <li><a class="active" href="#home">Home</a></li>
      <li><a href="#news">News</a></li>
      <li><a href="#contact">Contact</a></li>
      <li><a href="#about">About</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SecondaryNavbar",
  data() {
    return {};
  },
};
</script>
