var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"box mb-5 is-shadowless",class:_vm.classBackground},[_c('div',{staticClass:"search-form"},[_c('label',{staticClass:"label",attrs:{"for":"search-keyword"}},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),_c('div',{staticClass:"inputSearch"},[_c('v-select',{attrs:{"loading":_vm.loading,"filterable":false,"placeholder":_vm.placeholder,"reduce":function (ref) {
	var labelOriginal = ref.labelOriginal;

	return labelOriginal;
},"clearSearchOnBlur":function () { return false; },"clearSearchOnSelect":false,"options":_vm.dataFiltered,"label":"labelOriginal"},on:{"option:selected":_vm.selectOption,"search":_vm.fetchOptions},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({ref:"input",staticClass:"vs__search"},'input',Object.assign({}, attributes, {value: _vm.search}),false),events))]}},{key:"option",fn:function(ref){
var label = ref.label;
return [_c('div',{domProps:{"innerHTML":_vm._s(label)}})]}},{key:"no-options",fn:function(){return [_vm._v(" No result found ")]},proxy:true},{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({class:_vm.icon},'span',attributes,false))]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)])]),_vm._v(" "),_c('div',{staticClass:"columns is-multiline mb-0 contact-media-row"},[(_vm.noFound)?_c('section',{staticClass:"column"},[_vm._m(0)]):_vm._e(),_vm._v(" "),_vm._l((_vm.list),function(item,index){return _c('section',{key:index,staticClass:"column is-4-desktop"},[_c('div',{staticClass:"contacts-block contact-media has-background-white has-text-dark"},[_c('div',{staticClass:"columns is-multiline is-desktop"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"contact-card"},[_c('a',{attrs:{"href":item.url}},[_c('strong',{staticClass:"contacts-title"},[_vm._v(_vm._s(item.name))])]),_vm._v(" "),_c('ul',{staticClass:"contacts-list list-unstyled"},[_c('li',[_c('i',{staticClass:"icon icon-phone"}),_vm._v(" "),_c('a',{attrs:{"href":'tel:' + item.phone.replaceAll('-', '')}},[_vm._v(_vm._s(item.phone))])]),_vm._v(" "),_c('li',[_c('i',{staticClass:"icon icon-mail"}),_vm._v(" "),_c('a',{attrs:{"href":'mailto:' + item.email}},[_vm._v("Email")])])])])])])])])})],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contacts-block contact-media has-background-white has-text-dark"},[_c('div',{staticClass:"content has-text-centered"},[_c('strong',{staticClass:"contacts-title"},[_vm._v("No contact found")])])])}]

export { render, staticRenderFns }