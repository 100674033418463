<template>
  <span>{{ time }} minutes{{ time > 1 ? "s" : "" }}</span>
</template>

<script>
const WORDS_PER_MINUTE = 250; // It's a good aprox.

export default {
  name: 'ReadingTime',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    time() {
      const words = this.text.split(/\s/g).length;
      return Math.ceil(words / WORDS_PER_MINUTE);
    },
  }
};
</script>
