export default {
  state: {
    apiUrl: '',
    apiArticleUrl: '',
    searchInfo: {
      data: [],
      facets: {},
      pagination: {
        total: 0,
        previous_page: 0,
        current_page: 0,
        next_page: 0,
        last_page: 0,
        has_next: false
      }
    },
    searchFilters: {
      keyword: '',
      filters: {},
      sort: {}
    },
    optionsContentType: [],
    searchDefinitions: {},
    searchFilterOptions: {},
    isLoading: false,
    isLoadingArticle: false,
    isLoadingNewPage: false,
    articleHtml: '',
    selectedArticle: null,
    searchByTermDone: false,
  },
  getters: {
    getSearchInfo(state) {
      return state.searchInfo;
    },
    getSearchFilters(state) {
      return state.searchFilters;
    },
    getLoadingState(state) {
      return state.isLoading;
    },
    getLoadingArticleState(state) {
      return state.isLoadingArticle;
    },
    getLoadingNewPageState(state) {
      return state.isLoadingNewPage;
    },
    getArticleHtml(state) {
      return state.articleHtml;
    },
    getSelectedArticle(state) {
      return state.selectedArticle;
    },
    getSearchDefinitions(state) {
      return state.searchDefinitions;
    },
    getSearchFilterOptions(state) {
      return state.searchFilterOptions;
    },
    getSearchByTermDone(state) {
      return state.searchByTermDone;
    },
  },
  mutations: {
    setOptionsContentType(state, data) {
      state.optionsContentType = data;
    },
    setSearchInfo(state, data) {
      state.searchInfo = data;
    },
    setSearchFilters(state, data) {
      state.searchFilters = data;
    },
    setLoadingState(state, data) {
      state.isLoading = data;
    },
    setLoadingArticleState(state, data) {
      state.isLoadingArticle = data;
    },
    setLoadingNewPageState(state, data) {
      state.isLoadingNewPage = data;
    },
    setApiUrl(state, data) {
      state.apiUrl = data;
    },
    setApiArticleUrl(state, data) {
      state.apiArticleUrl = data;
    },
    setArticleHtml(state, data) {
      state.articleHtml = data;
    },
    setSelectedArticle(state, data) {
      state.selectedArticle = data;
    },
    setSearchDefinitions(state, data) {
      state.searchDefinitions = data;
    },
    setSearchFilterOptions(state, data) {
      state.searchFilterOptions = data;
    },
    setSearchByTermDone(state) {
      localStorage.setItem('searchByTermDone', true);
      state.searchByTermDone = true;
    },
    setInitiallizeStore(state){
      if (localStorage.getItem('searchByTermDone')) {
        state.searchByTermDone = true;
      }
    }
  },
  actions: {
    fetchGeneralSearch({ commit, rootState, dispatch }) {
      commit('setLoadingState', true);

      let searchURL = rootState.search.apiUrl;
      let urlParams = '?';

      console.log({urlParams});

      if (rootState.search.searchFilters.keyword && rootState.search.searchFilters.keyword.length > 0) {
        let keyword = rootState.search.searchFilters.keyword;
        urlParams += `keyword=${keyword.replace(/\&/g, '%26')}&`;
      }
      console.log({urlParams}, rootState.search.searchFilters.filters);

      for (let filterKey in rootState.search.searchFilters.filters) {
        if (
          typeof rootState.search.searchFilters.filters[filterKey] == 'array' ||
          typeof rootState.search.searchFilters.filters[filterKey] == 'object'
        ) {
          for (let filterValue of rootState.search.searchFilters.filters[filterKey]) {
            if (filterValue.length > 0) {
              urlParams += `filters[${filterKey}][]=${filterValue.replace(/\&/g, '%26')}&`;
            }
            console.log({urlParams}, rootState.search.searchFilters.filters);
          }
        } else {
          let filterValue = rootState.search.searchFilters.filters[filterKey];
          if (filterValue.length > 0) {
            urlParams += `filters[${filterKey}]=${filterValue.replace(/\&/g, '%26')}&`;
          }
          console.log({urlParams});
        }
      }
      console.log({urlParams});

      for (let sortKey in rootState.search.searchFilters.sort) {
        let sortValue = rootState.search.searchFilters.sort[sortKey];
        urlParams += `sort[${sortKey}]=${sortValue.replace(/\&/g, '%26')}&`;
      }

      console.log({urlParams});

      return fetch(`${searchURL}${urlParams.slice(0, -1)}`)
        .then(response => response.json())
        .then(async (response) => {
          const formattedFacets = {};

          for (let filterIndex in response.results.facets) {
            formattedFacets[filterIndex] = {
              showedOptions: response.results.facets[filterIndex].options.slice(0, 3),
              hiddenOptions: response.results.facets[filterIndex].options.slice(3)
            }
          }

          const actualUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
          window.history.pushState(
            { path: `${actualUrl}${urlParams.slice(0, -1)}` },
            '',
            `${actualUrl}${urlParams.slice(0, -1)}`
          );

          commit('setSearchInfo', { ...response.results, facets: formattedFacets });
          commit('setLoadingState', false);
          commit('setSelectedArticle', null);
          commit('setArticleHtml', '');

          if (response.results.data.length > 0 && rootState.search.apiArticleUrl != '') {
            dispatch("assignSelectedArticle", response.results.data[0]);
            dispatch("fetchArticleContent", response.results.data[0].location_id);
          }
        }).catch(function (error) {
          console.log('There is a problem with Fetch: ' + error.message);
          commit('setLoadingState', false);
          commit('setSelectedArticle', null);
          commit('setArticleHtml', '');
        });
    },
    assignApiUrl({ commit }, apiUrl) {
      commit('setApiUrl', apiUrl);
    },
    assignApiArticleUrl({ commit }, apiArticleUrl) {
      commit('setApiArticleUrl', apiArticleUrl);
    },
    assignSelectedArticle({ commit }, selectedArticle) {
      commit('setSelectedArticle', selectedArticle);
    },
    assignSearchDefinitions({ commit }, searchDefinitions) {
      commit('setSearchDefinitions', searchDefinitions);
    },
    assignSearchFilterOptions({ commit }, searchFilterOptions) {
      commit('setSearchFilterOptions', searchFilterOptions);
    },
    assignGeneralSearchInfo({ commit, rootState }, searchInfo) {
      const newSearchInfo = {
        ...rootState.search.searchInfo,
        ...searchInfo
      };

      commit('setSearchInfo', newSearchInfo);
    },
    assignGeneralSearchFilters({ commit, rootState }, searchFilters) {
      const newSearchFilters = {
        ...rootState.search.searchFilters,
        ...searchFilters
      };
      console.log({newSearchFilters})

      commit('setSearchFilters', newSearchFilters);
    },
    fetchNewPageSearch({ commit, rootState }, page) {
      commit('setLoadingNewPageState', true);

      let searchURL = rootState.search.apiUrl;
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.set('page', page);

      let newSearchUrl = window.location.protocol + "//" +
        window.location.host + window.location.pathname + '?' +
        searchParams.toString();

      return fetch(`${searchURL}?${searchParams.toString()}`)
        .then(response => response.json())
        .then(async (response) => {
          window.history.pushState({ path: newSearchUrl }, '', newSearchUrl);

          commit('setSearchInfo', {
            ...rootState.search.searchInfo,
            data: rootState.search.searchInfo.data.concat(response.results.data),
            pagination: response.results.pagination,
          });
          commit('setLoadingNewPageState', false);
        }).catch(function (error) {
          console.log('There is a problem with Fetch: ' + error.message);
          commit('setLoadingNewPageState', false);
        });
    },
    fetchArticleContent({ commit, rootState }, locationId) {
      commit('setLoadingArticleState', true);

      let articleUrl = `${rootState.search.apiArticleUrl}/${locationId}`;

      return fetch(articleUrl)
        .then(response => response.text())
        .then(async (response) => {
          commit('setArticleHtml', response);
          commit('setLoadingArticleState', false);
        }).catch(function (error) {
          console.log('There is a problem with Fetch: ' + error.message);
          commit('setLoadingArticleState', false);
        });
    },
    assignSearchByTermDone({ commit }) {
      commit('setSearchByTermDone');
    },
    assignInitiallizeStore({ commit }) {
      commit('setInitiallizeStore');
    }
  }
};
