<template>
  <section class="section-sticky">
    <div data-wrapper>
      <div :data-sticky="active ? 'true' : 'false'" class="container" data-aos="fade-up">
        <span class="bg-overlay" :class="bg"></span>
        <slot></slot>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "SectionSticky",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    bg: {
      type: String,
      default: "has-background-white",
    },
  },
  data() {
    return {
      height: "auto",
    };
  },
  mounted() {
    const $md = window.matchMedia("(min-width: 768px)");
    this.girdTemplate($md);
    $md.onchange = () => {
      this.girdTemplate($md);
    }
    if (this.active) this.$store.commit("PUSH_STICKY", this.$el);
  },
  methods: {
    girdTemplate($md) {
      const $ul = this.$el.querySelector("ul.nav-menu-wrapper");
      const $li = $ul.querySelectorAll("li");
      
      if ($li.length <= 2) {
        $ul.style.gridTemplateColumns = `repeat(${$md.matches ? 2 : 1}, 1fr)`;
        $ul.style.gridTemplateRows = $md.matches ? `repeat(1, 1fr)` : "";
      } else if ($li.length == 3) {
        $ul.style.gridTemplateColumns = `repeat(${$md.matches ? 3 : 1}, 1fr)`;
        $ul.style.gridTemplateRows = $md.matches ? `repeat(1, 1fr)` : "";
      } else if ($li.length > 3 && $li.length < 5) {
        $ul.style.gridTemplateColumns = `repeat(${$md.matches ? 2 : 1}, 1fr)`;
        $ul.style.gridTemplateRows = $md.matches ? `repeat(2, 1fr)` : "";
      } else {
        $ul.style.gridTemplateColumns = `repeat(${$md.matches ? 3 : 1}, 1fr)`;
        $ul.style.gridTemplateRows = $md.matches
          ? `repeat(${Math.ceil($li.length / 3)}, 1fr)`
          : "";
      }
    },
  },
};
</script>
