<template>
  <div class="aside-open-close" :class="{ 'is-open-close-active': isOpenNav }">
    <div class="content mb-0">
      <ul class="navigation-list-feature has-text-info">
        <template v-for="(item, index) in visibleList">
          <simple-option-menu-icon
            :title="item.text"
            :link="item.link"
            :key="`item-${index}`"
          />
        </template>
        <template v-for="(item, index) in expandibleList">
          <simple-option-menu-icon
            :title="item.text"
            :link="item.link"
            :key="`hidden-item-${index}`"
            :class="{ 'is-hidden': !isOpenNav }"
          />
        </template>
      </ul>
    </div>
    <div v-if="expandibleList.length > 0" class="btn-opener-holder mt-3">
      <a
        href="#"
        class="open-close-opener"
        @click="toggleNav($event)"
      >
        <span class="default-text">{{ seeMoreText }}</span>
        <span class="hidden-text">{{ HideText }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import CollapseMenuIcon from "../collapse/CollapseMenuIcon.vue";
import SimpleOptionMenuIcon from "../menu/SimpleOptionMenuIcon.vue";

export default {
  components: { CollapseMenuIcon, SimpleOptionMenuIcon },
  name: "OpenCloseWithSubNav",
  props: {
    list: {
      type: Array,
      required: true
    },
    seeMoreText: {
      type: String,
      required: false,
      default: "See all",
    },
    HideText: {
      type: String,
      required: false,
      default: "Hide",
    },
    optionsVisible: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      isOpenNav: false,
    };
  },
  computed: {
    visibleList() {
      return this.list.slice(0, this.optionsVisible);
    },
    expandibleList() {
      return this.list.slice(this.optionsVisible);
    }
  },
  methods: {
    toggleNav(e) {
      e.preventDefault();
      this.isOpenNav = !this.isOpenNav;
    },
  },
};
</script>
