<template>
  <div class="is-flex custom-columns">
    <template v-for="(columItems, index) in listItems">
      <div
        :key="`column-${index}-0`"
        :style="dinamycFlexBasis"
        :class="[index !== 0 && !isSecond ? 'ml-lg-4 mr-lg-4' : '']"
        class="full-width-none-desktop"
      >
        <template v-for="(item, secondaryIndex) in columItems">
          <ul
            v-if="secondaryIndex === columItems.length - 1"
            :class="[
              { 'initial-column': secondaryIndex === 0 },
              { 'blog-segment-not-bottom-border': !isSecond },
            ]"
            :key="`elem-${secondaryIndex}-a`"
          >
            <li v-html="item"></li>
          </ul>
          <ul
            v-else
            :class="[
              { 'initial-column': secondaryIndex === 0 },
              { 'blog-segment': !isSecond },
            ]"
            :key="`elem-${secondaryIndex}-b`"
          >
            <li v-html="item" />
          </ul>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "ColumnsDisplay",
  props: {
    listItems: {
      type: Array,
      required: true,
    },
    isSecond: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dinamycFlexBasis: "",
    };
  },
  created() {
    window.addEventListener("resize", this.setupFlexBasis);
    window.addEventListener("beforeprint", this.handlePrintEvent);
  },
  mounted() {
    this.setupFlexBasis();
  },
  destroyed() {
    window.removeEventListener("resize", this.setupFlexBasis);
    window.removeEventListener("beforeprint", this.handlePrintEvent);
  },
  methods: {
    handlePrintEvent() {
      this.dinamycFlexBasis = "";
    },
    setupFlexBasis() {
      const mediaQuery = window.matchMedia("(min-width: 1215px)");
      if (mediaQuery.matches) {
        const basis = Number.parseFloat(100 / this.listItems.length).toFixed(3);
        this.dinamycFlexBasis = `flex-basis: ${basis}%;`;
        return;
      }
      this.dinamycFlexBasis = "";
    },
  },
};
</script>
