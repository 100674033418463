<template>
  <div class="swiper slides-promo-flexible" :class="{onhover}" :style="{height:'100%'}">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- Slides -->

      <div
        v-for="(item, index) in slidesData"
        :key="`slide-promo-flexible-${index}-${_uid}`"
        class="swiper-slide"
        :style="{height: 'auto'}"
      >
        <article class="second-card" :class="[{onhover, hasTwoColumns: item.hasTwoColumns}, item.class, ['left', 'right'].includes(orientation) && 'is-horizontal', ['right'].includes(orientation) && 'is-invert' ]">
          <div v-if="item.image.src.length > 0" class="second-card-image" :class="{'onhover-image':onhoverImage}">
            <figure class="image">
              <img
                loading="lazy"
                :src="item.image.src"
                :alt="item.image.alt"
                :ref="`image-slide-${index}`"
              />
            </figure>
          </div>
          <div class="second-card-content">
            <div
              v-if="item.image.credit && item.image.credit.length > 0"
              class="has-text-left"
            >
              <span class="made-by">Photo: <a href="#">John Smith/AFP</a></span>
            </div>
            <template v-if="item.logo">
              <div
                v-if="item.logo.src.length > 0"
                class="partner-logo has-text-right"
              >
                <img loading="lazy" :src="item.logo.src" :alt="item.logo.alt" />
              </div>
            </template>
            <div class="content">
              <div class="second-card-title" v-if="item.title" v-html="item.title" />
              <div class="htmlContent mb-3" v-if="item.htmlContent" v-html="item.htmlContent" style="hyphens: none" />
              <div class="htmlContent2 mb-3" v-if="item.htmlContent2" v-html="item.htmlContent2" style="hyphens: none" />
              <template v-if="item.link.hasLink">
                <a
                  v-if="item.link.external"
                  :href="item.link.link"
                  target="blank"
                  class="link-external link"
                  :class="item.link.classCta"
                >
                  <span :class="item.link.classTextCta">{{
                    item.link.text
                  }}</span>
                </a>
                <a
                  v-else
                  :href="item.link.link"
                  class="link"
                  :class="item.link.classCta"
                >
                  <span :class="item.link.classTextCta">{{
                    item.link.text
                  }}</span>
                </a>
              </template>
            </div>
          </div>
        </article>
      </div>
    </div>
    <div class="ms-pagination" :class="orientation"></div>
  </div>
</template>

<script>
import Swiper from "swiper";
import { Pagination, EffectFade } from "swiper/modules";
export default {
  name: "SlidePromoFlexible",
  props: {
    slidesData: {
      type: Array,
      required: true,
    },
    orientation: {
      type: String,
      required: true,
    },
    onhover: {
      type: Boolean,
      default: false
    },
    onhoverImage: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.onInit();
  },
  methods: {
    onInit() {
      const $swiper = this.$el;
      
      if ($swiper) {
        const config = {
          effect: "fade",
          speed: 500,
          modules: [EffectFade, Pagination],
          loop: true,
        };

        config.pagination = {
          el: this.$el.querySelector(".ms-pagination"),
          clickable: true,
        };

        new Swiper($swiper, config);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
