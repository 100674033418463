<template>
  <div class="search-sort-card is-shadowless has-background-primary-lightest">
    <div class="card-content">
      <form class="search-sort-form" action="#">
        <div class="columns is-variable is-1">
          <div class="column is-4-tablet is-3-desktop">
            <div class="search-form">
              <div class="control">
                <label class="label" for="search-terms-3">{{
                  searchDefinitions.search_term_label &&
                  searchDefinitions.search_term_label.length > 0
                    ? searchDefinitions.search_term_label
                    : "Search keyword"
                }}</label>
                <input
                  type="text"
                  class="text"
                  id="search-terms-3"
                  placeholder="Keyword search"
                  :value="keyword"
                  v-on:keyup="updateSearchKeyword($event)"
                />
                <button
                  @click="callSearch($event)"
                  type="submit"
                  class="btn-search"
                >
                  <i class="icon icon-search"></i>
                  <span class="is-sr-only">{{
                    searchDefinitions.search_term_label
                  }}</span>
                </button>
              </div>
            </div>
          </div>
          <div
            class="column"
            v-for="facetIndex in Object.keys(facets).filter(
              (f) =>
                searchFilterOptions &&
                searchFilterOptions.options &&
                searchFilterOptions.options[f]
            )"
            :key="`filter-${facetIndex}`"
            :set="(facet = facets[facetIndex])"
          >
            <div class="control">
              <label class="label" for="content-type">{{
                searchFilterOptions.options[facetIndex].label
              }}</label>
              <div class="input-select">
                <v-select
                  :searchable="false"
                  :appendToBody="true"
                  :options="optionsContentType"
                  :multiple="true"
                  :closeOnSelect="false"
                  label="title"
                  inputId="contentType"
                  :reduce="({ value }) => value"
                  :value="contentType"
                >
                  <template #open-indicator>
                    <span></span>
                  </template>
                  <template #selected-option-container="{ option }">
                    <span>{{ option.title }}</span>
                  </template>
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      v-bind="attributes"
                      v-on="events"
                      placeholder="Select Type"
                    />
                  </template>
                  <template #option="{ title, value }">
                    <div class="vs__item_option" @click="onToggleOption(value)">
                      <span class="vs__item_option_label">{{ title }}</span>
                      <span class="vs__item_option_check"></span>
                    </div>
                  </template>
                </v-select>
                <div class="vs__tools">
                  <span v-if="contentType.length" class="length">
                    ({{ contentType.length }})
                  </span>
                  <span
                    v-show="contentType.length"
                    class="vs_clear"
                    @click="contentType = []"
                  >
                    <i v-icon:xmark></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="column"
            v-if="
              searchFilterOptions &&
              searchFilterOptions.options &&
              searchFilterOptions.options.date
            "
          >
            <div class="control datepicker">
              <label class="label" for="from">From</label>
              <div class="input-select">
                <vue-date-picker
                  no-header
                  v-model="date_from"
                  id="from"
                  :locale="local"
                  @onChange="handleChangeSingle(date_from, 'date_from')"
                  format="MM/DD/YYYY"
                  clearable
                  placeholder="MM/DD/YYYY"
                  format-header="MMMM DD, YYYY"
                />
              </div>
            </div>
          </div>
          <div
            class="column"
            v-if="
              searchFilterOptions &&
              searchFilterOptions.options &&
              searchFilterOptions.options.date
            "
          >
            <div class="control datepicker">
              <label class="label" for="to">To</label>
              <div class="input-select">
                <vue-date-picker
                  no-header
                  v-model="date_to"
                  @onChange="handleChangeSingle(date_to, 'date_to')"
                  format="MM/DD/YYYY"
                  clearable
                  id="to"
                  placeholder="MM/DD/YYYY"
                  format-header="MMMM DD, YYYY"
                  :locale="local"
                />
              </div>
            </div>
          </div>
          <div
            class="column"
            v-if="
              searchDefinitions.sorting_options &&
              searchDefinitions.sorting_options.length > 0
            "
          >
            <div class="control">
              <label class="label" for="sort-3">Sort</label>
              <div class="select">
                <custom-select
                  name="sort"
                  placeholder="Select Sort"
                  :options="sortingOptions"
                  @change="updateSearchSort"
                  :value="sort"
                ></custom-select>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "FiltersTaxonomySearch",
  inject: ["show"],
  data() {
    return {
      keyword: "",
      contentType: [],
      date_from: null,
      date_to: null,
      sort: null,
      local: { lang: "en" },
    };
  },
  computed: {
    ...mapGetters({
      searchFilters: "getSearchFilters",
      searchDefinitions: "getSearchDefinitions",
    }),
    sortingOptions() {
      const res = [];
      this.searchDefinitions.sorting_options.forEach(({ name, value }) => {
        res.push({ name: `${name} (des)`, value: `${value}-des` });
        res.push({ name: `${name} (asc)`, value: `${value}-asc` });
      });
      return res;
    },
    ...mapState({
      facets: (state) => state.search.searchInfo.facets,
      searchFilterOptions: (state) => state.search.searchFilterOptions,
      optionsContentType: (state) => state.search.optionsContentType,
    }),
  },
  methods: {
    onToggleOption(value) {
      if (this.contentType.includes(value)) {
        const index = this.contentType.indexOf(value);
        this.contentType.splice(index, 1);
      } else {
        this.contentType.push(value);
      }
    },
    callSearch(e) {
      e.preventDefault();

      this.$store.dispatch("assignGeneralSearchFilters", {
        keyword: this.keyword,
      });
      this.$store.dispatch("fetchGeneralSearch");
    },
    updateSearchKeyword(e) {
      e.preventDefault();
      this.keyword = e.target.value;

      if (e.keyCode === 13) {
        this.callSearch(e);
      }
    },
    handleChange: function (e, filterName) {
      const value = e;
      const filters = { ...this.searchFilters.filters, [filterName]: value };
      // if (value.length > 0) {
      //   filters[filterName] = [value];
      // } else {
      //   filters[filterName] = [];
      // }
      
      this.$store.dispatch("assignGeneralSearchFilters", { filters });
      return this.$store.dispatch("fetchGeneralSearch");
    },
    handleChangeSingle: function (e, filterName) {
      const value = e;
      const filters = { ...this.searchFilters.filters };

      if (value) {
        filters[filterName] = value;
      } else {
        filters[filterName] = "";
      }

      this.$store.dispatch("assignGeneralSearchFilters", { filters });
      this.$store.dispatch("fetchGeneralSearch");
    },
    updateSearchSort(e) {
      const sort = {};
      if(e){
        const [field, direction] = e.split("-");
        sort[field] = direction;
      }
      this.sort = e;

      this.$store.dispatch("assignGeneralSearchFilters", { sort });
      this.$store.dispatch("fetchGeneralSearch");
    },
    getQuery() {
      const query = window.location.search;
      if(!query) return null;
      let result = query.replace(/\?/gi, "");
      result = result.split("&");

      const obj = {};
      result.forEach((item) => {
        const [label, value] = item.split("=");

        const keys = label.match(/\w+/g);
        const typeContent = label.match(/\[\]/g);

        keys.forEach((key, index, arr) => {
          if (arr.length > 1) {
            if (index) {
              const bKey = arr[index - 1];
              if (typeContent) {
                if (!obj[bKey][key]) obj[bKey][key] = [];

                obj[bKey][key].push(value);
              } else {
                obj[bKey][key] = value;
              }
            } else {
              if (!obj[key]) obj[key] = {};
            }
          } else {
            obj[key] = value;
          }
        });
      });
      return obj;
    },
  },
  mounted() {
    const query = this.getQuery();
    if(query){
      if(query.keyword)
        this.keyword = query.keyword;

      if(query.filters){
        if(query.filters.content_type)
          this.contentType = query.filters.content_type;
  
        if(query.filters.date_from)
          this.date_from = query.filters.date_from;
  
        if(query.filters.date_to)
          this.date_to = query.filters.date_to;
      }

      if(query.sort){
        const [key] = Object.keys(query.sort);
        const value = query.sort[key];
        this.sort = `${key}-${value}`
      }

      this.$store.dispatch("assignGeneralSearchFilters", query);
      this.$store.dispatch("fetchGeneralSearch");
      this.show();
    }

    console.log({query});

    this.$watch(() => this.contentType, (e) => {
      const $promise = this.handleChange(e, 'content_type');
      if(!e.length)
        $promise.finally(() => {
          location.reload();
        })
    })
  },
};
</script>
