<template>
  <div class="columns">
    <div class="content-column column is-12">
      <div class="filter-btn-holder mb-4">
        <a href="#" @click="toggleFilterMobile($event)" class="filter-opener">Filter results</a>
      </div>
      <div v-if="filterTools" :class="[ filterIsSelected ? 'dropdown-animation': 'is-hidden' ]">
        <filters-taxonomy-search />
      </div>
      <taxonomy-search-results />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "TaxonomySearch",
  props: {
    apiUrl: {
      type: String,
      required: true
    },
    optionsContentType:{
      type: Array,
      required: true
    },
    results: {
      type: Object,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
    searchDefinitions: {
      type: Object,
      required: true
    },
    filterOptions: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      filterIsSelected: false,
      filterTools: false
    }
  },
  provide(){
    return {
      show: () => {
        this.filterIsSelected = true;
      }
    }
  },
  methods: {
    toggleFilterMobile(e) {
      e.preventDefault();
      this.filterIsSelected = !this.filterIsSelected;
    }
  },
  computed: {
    ...mapGetters({
      searchInfo: "getSearchInfo",
      searchFilters: "getSearchFilters"
    }),
    ...mapState({
      facets: (state) => state.search.searchInfo.facets,
    }),
  },
  mounted() {
    const formattedFacets = {};

    for (let filterIndex in this.results.facets) {
      formattedFacets[filterIndex] = {
        showedOptions: this.results.facets[filterIndex].options.slice(0, 3), 
        hiddenOptions: this.results.facets[filterIndex].options.slice(3)
      }
    }

    this.$store.dispatch("assignApiUrl", this.apiUrl);
    this.$store.dispatch("assignGeneralSearchInfo", { ...this.results, facets: formattedFacets });
    this.$store.dispatch("assignGeneralSearchFilters", this.filters);
    this.$store.dispatch("assignSearchDefinitions", this.searchDefinitions);
    this.$store.dispatch("assignSearchFilterOptions", this.filterOptions);
    this.$store.commit("setOptionsContentType", this.optionsContentType);

    this.filterTools = true;
    this.filterIsSelected = this.filterOptions.expanded_filters;
  },
};
</script>