<template>
  <div>
  <div :ref="mySelfRef" :style="heightCurrentElement">
    <section :class="styleClass">
      <slot />
    </section>
  </div>
  </div>
</template></div>

<script>
import { EventBus } from "../../EventBus";

export default {
  name: 'CarouselElement',
  props: {
    parentCarouselId: {
      type: String,
      required: true
    },
    styleClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      heightCurrentElement: 0,
      greaterHeight: '',
      dynamicClass: 'has-background-red'
    }
  },
  computed: {
    mySelfRef() {
      return `ref-${ this.parentCarouselId }`;
    }
  },
  watch: {
    heightCurrentElement(heightValue){
      EventBus.$on("calculationHeight", (heightValue) => {
      if (this.heightCarouselElement < heightValue) {
        this.heightCarouselElement = `height: ${ heightValue }px;`;
        console.log(`aca estoy ${ height }`);
      }
    });
    }
  },
  mounted() {
    this.heightCurrentElement = `${ this.$refs[ this.mySelfRef ].clientHeight }px;`;
    console.log(this.heightCurrentElement);
    EventBus.$emit("calculationHeight", this.heightCurrentElement);
  },
  methods: {
    /*calculationHeight() {
      this.heightCurrentElement = this.$refs[ this.mySelfRef ].clientHeight;
    }*/
  },
}
</script>
