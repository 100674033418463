<template>
  <div>
    <ul class="sub-nav has-text-info">
      <li v-for="item in listitems" :key="item.id">
        <div v-if="!item.subItems || item.subItems.length == 0">
          <a :href="item.link">{{ item.item }}</a>
        </div>
        <div v-else>
          <li class="dropdown" :class="{ 'is-active': activeDropdown }">
            <a :href="item.link" @click="toogleMenu($event)" class="dropdown-trigger">{{ item.item }}</a>
            <ul class="dropdown-menu">
              <li v-for="sub in item.subItems" :key="sub.id">
                <a :href="sub.link" class="dropdown-item">{{ sub.item }}</a>
              </li>
            </ul>
          </li>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'DropdownList',
  props: {
    listitems: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      activeDropdown: false,
    }
  },
  methods: {
    toogleMenu(e){
      e.preventDefault();
      this.activeDropdown = !this.activeDropdown;
    }
  },

}
</script>
