<template>
  <div class="promo-flexible-carousel">
    <div class="mask">
      <div class="slides columns" :class="uniqueClassIdentifier">
        <slot />
      </div>
    </div>
    <div class="container">
      <div class="control-buttons is-pulled-right mr-5 pr-5">
        <button
          class="control-slide-button"
          @click="slideScroll($event, 'prev')"
          title="Preview"
        ></button>
        <button
          class="control-slide-button"
          @click="slideScroll($event, 'next')"
          title="Next"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import { tns } from "../../../../node_modules/tiny-slider/src/tiny-slider";

export default {
  name: "PromoFlexibleCarousel",
  mounted() {
    this.slider.play();
  },
  methods: {
    slideScroll(e, action) {
      e.preventDefault();
      this.slider.goTo(action);
    },
  },
  computed: {
    uniqueClassIdentifier() {
      return `my-slider-${this._uid}`;
    },
    slider() {
      return tns({
        container: `.${this.uniqueClassIdentifier}`,
        items: 3,
        slideBy: "page",
        autoplay: true,
        controls: false,
        mouseDrag: true,
        swipeAngle: false,
        nav: false,
        autoplayButtonOutput: false,
        autoplayResetOnVisibility: false,
        responsive: {
          350: {
            items: 1,
          },
          640: {
            edgePadding: 20,
            gutter: 20,
            items: 1,
          },
          700: {
            gutter: 30,
            items: 2,
          },
          900: {
            items: 3,
          },
        },
      });
    },
  },
};
</script>
