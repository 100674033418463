<template>
  <li class="is-open-close-active">
		<a href="#"  @click="toggleItem($event)" v-text="title"></a>
		<ul :class="{ 'is-hidden' : itemsHidden }">
			<slot />
		</ul>
	</li>
</template>

<script>
export default {
  name: 'CollapseMenuItem',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      itemsHidden: false,
    }
  },
  methods: {
    toggleItem(e){
      e.preventDefault();
      this.itemsHidden = !this.itemsHidden;
    }
  },
}
</script>
