<template>
  <div>
	  <div class="filter-block is-open-close-active">
      <div class="slide" :class="{ 'is-hidden' : loadMore }">
        <slot />
      </div>
      <div class="has-text-centered" @click="toggleCollapseBlock">
				<a href="#" class="load-more-link">
          <span class="hidden-text">{{ optionAvailableText }}</span>
        </a>
			</div>
	  </div>
  </div>
</template>

<script>
export default {
  name: 'CollapseBlock',
  data() {
    return {
      loadMore: true,
      optionAvailableText: 'Load more'
    }
  },
  methods: {
    toggleCollapseBlock(e){
      e.preventDefault();
      this.loadMore = !this.loadMore;
      this.optionAvailableText = this.loadMore ? 'Load more' : 'Show less';
    }
  },
}
</script>
