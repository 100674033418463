<template>
  <div :class="mobile ? 'second-mobile' : 'second-desktop'">
    <div class="second-search">
      <div class="second-search-btn">
        <button
          class="second-search-btn-open"
          aria-label="Open Search"
          :class="{ open }"
          @click="open = true"
        >
          <i v-icon:search></i>
        </button>
        <button
          class="second-search-btn-close"
          :class="{ open }"
          aria-label="Close Search"
          @click="open = false"
        >
          <i v-icon:xmark></i>
        </button>
      </div>
      <div class="second-search-wrapper" :class="{ open }">
        <div class="container">
          <div class="second-search-content">
            <slot></slot>
          </div>
        </div>
        <div class="second-search-overlay"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VSearchButton",
  data() {
    return {
      open: false,
      mobile: false 
    };
  },
  mounted() {
    this.onWindowsSize();
    window.addEventListener("resize", this.onWindowsSize);
  },
  watch: {
    open(a) {
      if (!this.mobile)
        document.querySelector("html").style.overflow = a ? "hidden" : '';
    }
  },
  methods: {
    onWindowsSize() {
      this.mobile = window.innerWidth < 768;
      if (this.mobile) {
        document.querySelector("html").style.overflow = '';
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
