<template>
  <filter-news
    :title="searchDefinitions.title"
    filter-tag="Filter"
    :expanded="searchFilterOptions.expanded_filters"
  >
    <div
      class="card search-sort-card filter-card is-shadowless has-background-primary-lightest"
    >
      <div class="card-content">
        <form class="search-sort-form" action="#">
          <div class="columns is-multiline is-variable is-1 mb-0">
            <div
              class="column pb-0"
              v-for="facetIndex in Object.keys(facets).filter(
                (f) =>
                  searchFilterOptions &&
                  searchFilterOptions.options &&
                  searchFilterOptions.options[f]
              )"
              :key="`filter-${facetIndex}`"
              :set="(facet = facets[facetIndex])"
            >
              <div class="control">
                <label class="label" :for="`${facetIndex}__input`">{{
                  searchFilterOptions.options[facetIndex].label
                }}</label>
                <div class="select">
                  <select
                    :id="`${facetIndex}__input`"
                    @change="handleChange($event, facetIndex)"
                  >
                    <option value="">All</option>
                    <option
                      v-for="(option, index) of facet.showedOptions"
                      :key="`${facetIndex}-option-${index}`"
                      :value="option.value"
                      :selected="
                        selectFilters.filters[facetIndex] &&
                        selectFilters.filters[facetIndex].includes(
                          option.value.toString()
                        )
                      "
                    >
                      {{ option.title | uppercaseAcronym }}
                    </option>
                    <option
                      v-for="(option, index) of facet.hiddenOptions"
                      :key="`${facetIndex}-option-${index}`"
                      :value="option.value"
                      :selected="
                        selectFilters.filters[facetIndex] &&
                        selectFilters.filters[facetIndex].includes(
                          option.value.toString()
                        )
                      "
                    >
                      {{ option.title | uppercaseAcronym }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div
              class="column pb-0"
              v-if="
                searchFilterOptions &&
                searchFilterOptions.options &&
                searchFilterOptions.options.date
              "
            >
              <div class="control datepicker">
                <label class="label" for="from">From</label>
                <input
                  type="date"
                  class="input"
                  id="from"
                  min="2000-01-01"
                  :max="maxDateToday"
                  placeholder="Any date"
                  @change="handleChangeDate($event, 'date_from')"
                  :value="
                    selectFilters.filters['date_from']
                      ? selectFilters.filters['date_from']
                      : ''
                  "
                />
              </div>
            </div>
            <div
              class="column pb-0"
              v-if="
                searchFilterOptions &&
                searchFilterOptions.options &&
                searchFilterOptions.options.date
              "
            >
              <div class="control datepicker">
                <label class="label" for="to">To</label>
                <input
                  type="date"
                  class="input"
                  id="to"
                  :min="minDateToDate"
                  :max="maxDateToday"
                  placeholder="Any date"
                  @change="handleChangeDate($event, 'date_to')"
                  :value="
                    selectFilters.filters['date_to']
                      ? selectFilters.filters['date_to']
                      : ''
                  "
                />
              </div>
            </div>

            <div
              class="column pb-0"
              v-if="
                searchDefinitions.sorting_options &&
                searchDefinitions.sorting_options.length > 0
              "
            >
              <div class="control">
                <label class="label" for="sort-3">Sort</label>
                <div class="select">
                  <custom-select
                    name="sort"
                    placeholder="Select Sort"
                    :options="sortingOptions"
                    @change="updateSearchSort"
                  ></custom-select>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </filter-news>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "SitePageFilters",
  filters: {
    uppercaseAcronym: function (value) {
      const regex = / *\([^)]*\) */g;
      const acronym = regex.exec(value);
      return !!acronym ? value.replace(regex, acronym[0].toUpperCase()) : value;
    },
  },
  data() {
    return {
      dateFrom: "",
    };
  },
  computed: {
    ...mapGetters({
      selectFilters: "getSearchFilters",
      searchDefinitions: "getSearchDefinitions",
    }),
    ...mapState({
      facets: (state) => state.search.searchInfo.facets,
      searchFilterOptions: (state) => state.search.searchFilterOptions,
    }),
    sortingOptions() {
      const res = [];
      this.searchDefinitions.sorting_options.forEach(({ name, value }) => {
        res.push({ name: `${name} (des)`, value: `${value}-des` });
        res.push({ name: `${name} (asc)`, value: `${value}-asc` });
      });
      return res;
    },
    maxDateToday() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      return yyyy + "-" + mm + "-" + dd;
    },
    minDateToDate() {
      if (this.dateFrom == "") {
        return "";
      }

      let [yyyy, mm, dd] = this.dateFrom.split("-");
      dd = parseInt(dd) + 1;

      if (dd < 10) {
        dd = "0" + dd;
      }

      return yyyy + "-" + mm + "-" + dd;
    },
  },
  methods: {
    handleChange: function (e, filterName) {
      const value = e.target.value;
      const filters = { ...this.selectFilters.filters };

      if (value.length > 0) {
        filters[filterName] = [value];
      } else {
        filters[filterName] = [];
      }

      this.$store.dispatch("assignGeneralSearchFilters", { filters });
      this.$store.dispatch("fetchGeneralSearch");
    },
    handleChangeDate: function (e, filterName) {
      const value = e.target.value;
      const filters = { ...this.selectFilters.filters };

      if (filterName == "date_from") {
        this.dateFrom = value;
      }

      if (value.length > 0) {
        filters[filterName] = value;
      } else {
        filters[filterName] = "";
      }

      this.$store.dispatch("assignGeneralSearchFilters", { filters });
      this.$store.dispatch("fetchGeneralSearch");
    },
    updateSearchSort(e) {
      const sort = {};
      const [field, direction] = e.split("-");

      sort[field] = direction;

      this.$store.dispatch("assignGeneralSearchFilters", { sort });
      this.$store.dispatch("fetchGeneralSearch");
    },
  },
};
</script>
