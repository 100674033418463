<template>
  <div class="columns is-block is-clearfix">
    <div class="column is-full pb-0 mb-0" v-if="!searchDefinitions.hide_title">
      <div class="section-heading filter-heading site-page-heading pb-0">
        <h3 v-html="searchDefinitions.title"></h3>
      </div>
    </div>
    <aside
      class="aside-column column is-4-tablet is-pulled-left hide-for-print"
    >
      <div class="section-heading filter-heading">
        <h3></h3>
      </div>

      <site-page-keyword></site-page-keyword>

      <div class="content mb-3">
        <span class="search-result has-text-weight-medium has-text-dark">
          {{ searchInfo.pagination.total }}
          {{ searchInfo.pagination.total == 1 ? "Result" : "Results" }}
        </span>
      </div>

      <site-page-results></site-page-results>
      <hr class="is-hidden-tablet -mt-2">
    </aside>
    <div class="content-column column is-8-tablet is-12-print is-pulled-right">
      <site-page-filters class="hide-for-print"></site-page-filters>

      <site-page-article></site-page-article>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SitePageSearch",
  props: {
    apiUrl: {
      type: String,
      required: true,
    },
    apiArticleUrl: {
      type: String,
      required: true,
    },
    results: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    searchDefinitions: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      searchInfo: "getSearchInfo",
      searchFilters: "getSearchFilters",
    }),
  },
  mounted() {
    const formattedFacets = {};
    const selectedArticle =
      this.results.data.length > 0 ? this.results.data[0].location_id : null;

    for (let filterIndex in this.results.facets) {
      formattedFacets[filterIndex] = {
        showedOptions: this.results.facets[filterIndex].options.slice(0, 3),
        hiddenOptions: this.results.facets[filterIndex].options.slice(3),
      };
    }

    this.$store.dispatch("assignApiUrl", this.apiUrl);
    this.$store.dispatch("assignApiArticleUrl", this.apiArticleUrl);
    this.$store.dispatch("assignGeneralSearchInfo", {
      ...this.results,
      facets: formattedFacets,
    });

    this.$store.dispatch("assignGeneralSearchFilters", this.filters);
    this.$store.dispatch("assignSearchDefinitions", this.searchDefinitions);
    this.$store.dispatch("assignSearchFilterOptions", this.filterOptions);

    if (selectedArticle !== null) {
      this.$store.dispatch("assignSelectedArticle", this.results.data[0]);
      this.$store.dispatch("fetchArticleContent", selectedArticle);
    }
  },
};
</script>
