<template>
  <div class="inputSearch">
    <v-select
      v-model="inputValue"
      :reduce="({ labelOriginal }) => labelOriginal"
      :clearSearchOnBlur="() => false"
      :clearSearchOnSelect="false"
      :options="dataFiltered"
      @option:selected="selectOption"
      @search="(res) => search = res"
      label="label"
    >
      <template #search="{ attributes, events }">
        <input
          class="vs__search"
          v-bind="{...attributes, value: search}"
          v-on="events"
        />
      </template>
      <template #option="{ label, url }">
        <span
          v-html="label"
        ></span>
      </template>
      <template #open-indicator="{ attributes }">
        <span :class="icon" v-bind="attributes"></span>
      </template>
    </v-select>
  </div>
</template>

<script>

export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "Search",
    },
    icon: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      inputValue: "",
      search: "",
      focusToggle: false,
    };
  },
  watch:{
    inputValue(v){
      this.search = v;

    }
  },
  computed: {
    dataFiltered() {
      this.options.sort((a, b) => a.label.localeCompare(b.label))
      let filter = this.options.filter((el) =>
        el.label.toLowerCase().includes(this.search ? this.search.toLowerCase() : '' )
      );

      filter = filter.map(el => {
        const regex = new RegExp(this.search, 'gi')
        return {
          url: el.url,
          labelOriginal: el.label,
          label: el.label.replace(regex, (str) => {
            return `<span class="text-blue">${str}</span>`
          })
        }
      });

      filter.sort((a, b) => a.label.localeCompare(b.label));
      return filter;
    },
    classComputed() {
      if (!this.focusToggle) return;
      return "activated";
    },
  },
  methods: {
    selectOption({url}){
      window.location = url;
    }
  }
};
</script>

<style></style>
