<template>
  <div class="special-nav-full-width">
    <div class="columns">
      <div v-for="(column, index) in elementsByColumn"
        :key="`column-${index}`"
        :class="`column is-${ classDistributionColumn }`"
        class="py-only-mobile-1"
      >
        <ul class="is-bulletsless has-text-info has-text-weight-semibold">
          <li
            v-for="(element, indexProduct) in column"
            :key="`product-${ indexProduct }`"
            class="py-only-mobile-1"
          >
            <a :href="element.link" v-html="element.text" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationProductFullWidth",
  props: {
    productList: {
      type: Array,
      required: true,
    },
    columnsToDisplay: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      elementsByColumn: [],
      bulmaGrid: 12
    };
  },
  mounted() {
    this.elementsByColumn = this.splitToChunks(
      this.productList,
      this.columnsToDisplay
    );
  },
  computed: {
    classDistributionColumn() {
      return this.bulmaGrid / this.columnsToDisplay;
    }
  },
  methods: {
    splitToChunks(array, parts) {
      let result = [];
      for (let i = parts; i > 0; i--) {
        result.push(array.splice(0, Math.ceil(array.length / i)));
      }
      return result;
    },
  },
};
</script>
