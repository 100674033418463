export default {
  methods: {
    setFixedArea($el, pos) {
      let $fixed = document.querySelector("#fixedArea");

      if ($fixed) {
        if ($el)
          $fixed.insertBefore($el, pos === null ? null : $fixed.children[0]);
      } else {
        const $app = document.querySelector("#app");

        $fixed = document.createElement("section");
        $fixed.setAttribute("id", "fixedArea");

        $app.append($fixed);

        if ($el)
          $fixed.insertBefore($el, pos === null ? null : $fixed.children[0]);
      }
    },
  },
};
