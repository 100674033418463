<template>
  <div class="news-slider">
    <!-- Slider main container -->
    <div class="swiper">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper" ref="wrapper">
        <!-- Slides -->
        <slot></slot>
      </div>

      <!-- If we need navigation buttons -->
      <div class="ms-arrows">
        <button class="ms-arrow ms-left"><i v-icon:arrow-left-alt></i></button>
        <button class="ms-arrow ms-right">
          <i v-icon:arrow-right-alt></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";

export default {
  name: "VMainBanner",
  props: {
    items: Array,
    require: true,
  },
  mounted() {
    this.onInit();
  },
  methods: {
    onInit() {
      const $swiper = this.$el.querySelector(".swiper");
      const $wrapper = this.$refs['wrapper'];


      if ($swiper) {
        $wrapper.children.forEach((item) => {
          item.classList.add("swiper-slide");
        })
        const nextEl = this.$el.querySelector(".ms-right");
        const prevEl = this.$el.querySelector(".ms-left");
        new Swiper($swiper, {
          navigation: {
            nextEl: nextEl,
            prevEl: prevEl,
          },
          spaceBetween: 40,
          speed: 500,
          slidesPerView: 3,
          slidesPerGroup: 3,
          modules: [Navigation],
        });
      }
    },
  },
};
</script>
