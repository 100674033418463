<template>
  <div class="input-select">
    <v-select
      :searchable="false"
      :appendToBody="true"
      :options="options"
      :placeholder="placeholder"
      label="name"
      @input="onChange"
      :reduce="({ value }) => value"
      :value="value"
    >
      <template #open-indicator>
        <span class="vs__arrow"></span>
      </template>
      <template #search="{ attributes, events }">
        <input class="vs__search" v-bind="attributes" v-on="events" />
        <input type="hidden" :name="name" :value="v" />
      </template>
      <template #option="{ name }">
        <div class="vs__item_option">
          <span class="vs__item_option_label">{{ name }}</span>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      default: null
    }
  },
  model: {
    prop: "value",
    event: "change"
  },
  data(){
    return {
      v: this.value
    }
  },
  methods: {
    onChange(e){
      this.v = e;
      this.$emit("change", this.v);
    }
  }
};
</script>

<style lang="scss" scoped></style>
