<template>
  <div class="news-articles mt-3">
    <article>
      <span class="search-result has-text-weight-medium has-text-dark">
        {{ searchInfo.pagination.total }}
        {{ searchInfo.pagination.total == 1 ? "Result" : "Results" }}
      </span>
    </article>

    <article-item
      v-for="(articleItem, index) in searchInfo.data"
      :key="`article-${index}`"
      :article="articleItem"
    />

    <div v-if="searchInfo.pagination.has_next" class="filter-block is-open-close-active">
      <div class="has-text-centered">
				<a href="#" class="load-more-link" @click="loadMoreResults($event)">
          <span class="hidden-text">{{ isLoadingNewPage ? 'Loading...' : 'Load More' }}</span>
        </a>
			</div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
  name: "GeneralSearchResults",
  computed: {
    ...mapGetters({
      searchInfo: "getSearchInfo",
      isLoadingNewPage: "getLoadingNewPageState",
    }),
  },
  methods: {
    loadMoreResults(e) {
      e.preventDefault();

      if (!this.isLoadingNewPage) {
        this.$store.dispatch('fetchNewPageSearch', this.searchInfo.pagination.next_page);
      }
    }
  }
};
</script>
