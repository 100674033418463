import MyIcons from '../icons';

const fetchIcon = name => {
  const icons = MyIcons;

  if (icons === undefined) {
    console.error('Icons not loaded');
    return;
  }

  const icon = icons[name];

  if (icon === undefined) {
    console.warn(`Icon '${name}' does not exist`);
    return null;
  }

  return icon
};

let handleOutsideClick;
const directives = {
  icon: {
      bind(el, { arg }) {
        const icon = fetchIcon(arg)
        el.innerHTML = `<svg viewBox="${icon.viewBox}">${icon.content}</svg>`;
      },
      update(el, {arg}) {
        const icon = fetchIcon(arg)
        el.innerHTML = `<svg viewBox="${icon.viewBox}">${icon.content}</svg>`;
      }
  },
  'closable': {
    bind (el, binding, vnode) {
      handleOutsideClick = (e) => {
        e.stopPropagation()
        const { handler, exclude } = binding.value
        let clickedOnExcludedEl = false
        exclude.forEach(refName => {
          if (!clickedOnExcludedEl) {
            const excludedEl = vnode.context.$refs[refName]
            clickedOnExcludedEl = excludedEl.contains(e.target)
          }
        })
        if (!el.contains(e.target) && !clickedOnExcludedEl) {
          vnode.context[handler]()
        }
      }
      document.addEventListener('click', handleOutsideClick)
      document.addEventListener('touchstart', handleOutsideClick)
    },
    unbind () {
      document.removeEventListener('click', handleOutsideClick)
      document.removeEventListener('touchstart', handleOutsideClick)
    }
  }
};

export default directives;
