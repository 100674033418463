<template>
  <div>
    <div class="section-results">
      <section
        v-for="(item, index) in results"
        :key="`result-${index}`"
        class="card card-news is-horizontal-desktop is-shadowless has-text-weight-medium px-0"
      >
        <div class="card-content">
          <div class="content">
            <h4>
              <a :href="item.link" v-html="item.title" />
            </h4>
            <div class="meta">
              <span class="category-label is-uppercase has-text-primary">
                <a href="#">EQUINOX</a>
              </span>
              <time class="date" :datetime="item.date" v-html="item.date" />
            </div>
            <p v-html="item.summary" />
            <div class="tags">
              <a
                v-for="(tag, tagIndex) in item.tags"
                :key="`tag-${tagIndex} * ${index}`"
                class="tag button is-primary is-outlined is-uppercase"
                :href="tag.link"
                v-html="tag.name"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-if="articleList.length > limit" class="has-text-centered">
      <div v-if="emptyResults" class="has-text-grey-light-alt my-2">
        <p>{{ statusMessage }}</p>
      </div>
      <a href="#" @click="displayMoreResults($event)" class="load-more-link">
        <span class="hidden-text">{{ label }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfinityScrolling",
  props: {
    articleList: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
      default: 10,
    },
    label: {
      type: String,
      default: "Load more",
    },
  },
  data() {
    return {
      results: [],
      internalLimit: 0,
      emptyResults: false,
      statusMessage: "",
      htmlContent: "",
    };
  },
  mounted() {
    this.internalLimit = this.limit;
    this.loadInitialResults();
  },
  methods: {
    loadInitialResults() {
      if (this.articleList.length <= 0) {
        this.emptyResults = true;
        this.statusMessage = 'No results found';
        return;
      }

      this.results = this.articleList.slice(0, this.internalLimit);
    },
    displayMoreResults(e) {
      e.preventDefault();
      this.resetStatusDisplayResults();
      this.internalLimit += this.limit;

      if (this.internalLimit > (this.articleList.length + this.limit)) {
        this.emptyResults = true;
        this.statusMessage = "No results found";
        return;
      }

      this.results = this.articleList.slice(0, this.internalLimit);
    },
    resetStatusDisplayResults() {
      this.emptyResults = false;
      this.statusMessage = "";
    }
  },
};
</script>
