<template>
  <main id="main">
    <slot></slot>
  </main>
</template>
<script>
import { initAOS } from "../utils/aos";
export default {
  name: "VMain",
  props: {
    disable: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    initAOS(this.disable);
  }
}
</script>

<style lang="scss" scoped></style>