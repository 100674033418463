<template>
  <div class="news-carousel hide-for-print" :class="{ 'is-hidden' : !readyToShow }">
    <div :class="uniqueClassIdentifier" v-cloak>
      <slot />
    </div>
    <div v-if="amountSlides > slidesByRow" class="carousel-controls">
      <a href="#" title="Previous" class="button-control is-prev" :class="prevButtonClass" @click="slideScroll($event, 'prev')">
        <span
          class="icon icon-transparent-fill is-small"
          v-icon:medium-arrow-left
        />
      </a>
      <a href="#" title="Next" class="button-control is-next" :class="nextButtonClass" @click="slideScroll($event, 'next')">
        <span
          class="icon icon-transparent-fill is-small"
          v-icon:medium-arrow-right
        />
      </a>
    </div>
  </div>
</template>

<script>
import { tns } from '../../../../node_modules/tiny-slider/src/tiny-slider';

export default {
  name: 'Carousel',
  data() {
    return {
      slidesByRow: 3,
      amountSlides: '',
      offset: 0,
      prevButtonClass: '',
      nextButtonClass: '',
      readyToShow: false
    }
  },
  created() {
    window.addEventListener("resize", this.checkAndSetMobileSetup);
  },
  mounted() {
    do {
      this.readyToShow = this.slider.isOn;
    } while (!this.readyToShow);
    this.amountSlides = parseInt(this.slider.getInfo().slideCount);
    this.setupCarouselControls();

    const nodeList = document.querySelectorAll('.tns-item');
    for (const simpleNode of nodeList) {
      simpleNode.ariaHidden = "false";
    }
  },
  destroyed() {
    window.addEventListener('resize', this.checkAndSetMobileSetup);
  },
  computed: {
    uniqueClassIdentifier(){
      return `my-slider-${this._uid}`;
    },
    slider() {
      return tns({
        container: `.${this.uniqueClassIdentifier}`,
        items: this.slidesByRow,
        slideBy: 'page',
        controls: false,
        mouseDrag: true,
        swipeAngle: false,
        nav: false,
        autoplayButtonOutput: false,
        autoplayResetOnVisibility	: false,
        loop: false,
        responsive: {
          350: {
            items: 1
          },
          640: {
            gutter: 20,
            items: 1
          },
          700: {
            gutter: 30,
            items: 2
          },
          900: {
            items: 3
          }
        }
      });
    }
  },
  methods: {
    slideScroll(e, action){
      e.preventDefault();
      this.checkAndSetMobileSetup();

      this.slider.goTo(action);
      if (action === 'prev' && this.offset > 0) {
        this.offset = this.offset - this.slidesByRow;
      }
      else if (action === 'next' && this.offset < this.amountSlides) {
        this.offset = this.offset + this.slidesByRow;
      }
      this.setupCarouselControls();
    },
    setupCarouselControls(){
      this.checkAndSetMobileSetup();
      this.calculateClassForButtons();
    },
    calculateClassForButtons() {
      this.prevButtonClass = (this.offset > 0 && this.amountSlides > this.slidesByRow) ? '' : 'is-hidden';
      this.nextButtonClass = ((this.offset + this.slidesByRow) < this.amountSlides) ? '' : 'is-hidden';
    },
    checkAndSetMobileSetup(){
      const checkIsMobile = window.matchMedia('(max-width: 684px)');
      const checkIsLandScape = window.matchMedia('(max-width: 960px)');
      if (checkIsMobile.matches) {
        this.slidesByRow = 1;
      }
      else if (checkIsLandScape.matches) {
        this.slidesByRow = 2;
      }
      else {
        this.slidesByRow = 3;
      }
      this.calculateClassForButtons();
    }
  },
};
</script>

<style>
 [v-cloak] { display: none }

</style>
