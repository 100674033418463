<template>
  <section class="section skeleton article">
    <div class="container">
      <h1><span class="skeleton__item">Lorem ipsum</span></h1>
      <h4><span class="skeleton__item">Lorem upsum dolor sit ammet</span></h4>

      <div class="columns is-clearfix mt-5">
        <div class="column is-4-desktop">
          <div class="skeleton__item block">
            <img loading="lazy" src="#" alt="image description" />
          </div>
        </div>
        <div class="column is-8-desktop">
          <p>
            <span class="skeleton__item">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Voluptatum, odio cumque!
            </span>
          </p>
          <p>
            <span class="skeleton__item">
              Laboriosam ducimus sapiente blanditiis quibusdam non tenetur
              adipisci nemo? Quia cupiditate eaque a culpa? Inventore hic in eos
              error! Quia cupiditate eaque a culpa? Inventore hic in eos error!
              Inventore hic in eos error!
            </span>
          </p>
        </div>
      </div>

      <div class="columns is-clearfix">
        <div class="column is-12">
          <p>
            <span class="skeleton__item">Lorem ipsum dolor, sit amet.</span
            ><br />
            <span class="skeleton__item"
              >consectetur adipisicing elit. Sed eveniet culpa numquam
              voluptatum exercitationem ab vitae, cumque.</span
            ><br />
            <span class="skeleton__item"
              >quo laboriosam dolore unde velit sit temporibus architecto.
              Aperiam soluta unde natus necessitatibus!</span
            >
          </p>
          <p>
            <span class="skeleton__item">
              Laboriosam ducimus sapiente blanditiis quibusdam non tenetur
              adipisci nemo? Quia cupiditate eaque a culpa? Inventore hic in eos
              error! Quia cupiditate eaque a culpa? Inventore hic in eos error!
            </span>
          </p>
        </div>
      </div>

      <div class="columns is-clearfix">
        <div class="column is-6-tablet">
          <div class="skeleton__item block">
            <img loading="lazy" src="#" alt="image description" />
          </div>
        </div>
        <div class="column is-6-tablet">
          <div class="skeleton__item block">
            <img loading="lazy" src="#" alt="image description" />
          </div>
        </div>
      </div>

      <div class="columns is-clearfix">
        <div class="column is-12">
          <p>
            <span class="skeleton__item block">
              <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            </span>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Skeleton",
};
</script>