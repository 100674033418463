<template>
  <div>
    <template v-for="(item, index) in heroCollection">
      <section
        v-if="item.textBlockToLeft"
        :class="[ mainClass, getProperlyWidthClass(item.textBlockToLeft) ,visibilityClass(!item.makeVisible) ]"
        :key="`hero-featured-${index}`"
      >
        <div class="card-image hide-for-print">
          <figure class="image is-3by2">
            <img loading="lazy"
              :src=item.image
              alt="image description"
            />
          </figure>
        </div>
        <div
          v-if="item.link && item.link.link"
          class="card-content"
          :class="cursorClass"
          @mouseover="changeCursorCtaMode()"
          @click="goToLink(item.link.link)"
        >
          <div class="content">
            <h2 v-html="item.title"></h2>
            <p>{{ item.description }}</p>
          </div>
          <template v-if="item.photoCredit">
            <div v-if="item.photoCredit.length > 0">
              <span class="made-by hide-for-print" :class="classText" v-html="item.photoCredit" />
            </div>
          </template>
        </div>
        <div
          v-else
          class="card-content"
        >
          <div class="content">
            <h2 v-html="item.title"></h2>
            <p>{{ item.description }}</p>
          </div>
          <template v-if="item.photoCredit">
            <div v-if="item.photoCredit.length > 0">
              <span class="made-by" :class="classText" v-html="item.photoCredit" />
            </div>
          </template>
        </div>
      </section>
      <section
        v-else
        :class="[ mainClass, getProperlyWidthClass(item.textBlockToLeft) ,visibilityClass(!item.makeVisible) ]"
        class="is-flex is-flex-direction-row"
        :key="`hero-featured-${index}`"
      >
        <div class="card-image">
          <figure class="image is-3by2">
            <img loading="lazy"
              :src=item.image
              alt="image description"
            />
          </figure>
        </div>
        <div
          v-if="item.link && item.link.link"
          :class="cursorClass"
          @mouseover="changeCursorCtaMode()"
          @click="goToLink(item.link.link)"
          class="card-content"
        >
          <div class="content">
            <h2 v-html="item.title"></h2>
            <p>{{ item.description }}</p>
          </div>
          <template v-if="item.photoCredit">
            <div v-if="item.photoCredit.length > 0">
              <span class="made-by" :class="classText" v-html="item.photoCredit" />
            </div>
          </template>
        </div>
        <div
          v-else
          class="card-content"
        >
          <div class="content">
            <h2 v-html="item.title"></h2>
            <p>{{ item.description }}</p>
          </div>
          <template v-if="item.photoCredit">
            <div v-if="item.photoCredit.length > 0">
              <span class="made-by" :class="classText" v-html="item.photoCredit" />
            </div>
          </template>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
export default {
  name: 'HeroFeaturedManager',
  props: {
    heroOptions: {
      type: Array,
      required: true,
    },
    classText: {
      type: String,
      required: true
    },
    classBg: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      cursorClass: '',
      heroCollection: [],
    }
  },
  methods: {
    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    },
    visibilityClass(notVisibility){
      return notVisibility ? 'is-hidden' : '';
    },
    getProperlyWidthClass(textBlockToLeft = true){
      return (textBlockToLeft) ? 'full-width' : 'full-width-variant';
    },
    changeCursorCtaMode(){
      this.cursorClass = "cursor-pointer";
    },
    goToLink(link) {
      location.href = link;
    },
    buildHeroCollection() {
      let randomHeroIndex;
      if (this.heroOptions.length > 1) {
        do {
          randomHeroIndex = this.getRandomInt(this.heroOptions.length);
        } while (parseInt(localStorage.lastRandomHeroFeaturedIndex) === parseInt(randomHeroIndex));
        localStorage.lastRandomHeroFeaturedIndex = randomHeroIndex;
      }
      else {
        randomHeroIndex = 0;
      }
      this.heroCollection = this.heroOptions.map((hero, index) => ({
          ...hero,
          makeVisible: (randomHeroIndex === index),
      }));
    }
  },
  mounted() {
    if (!localStorage.lastRandomHeroFeaturedIndex) {
      localStorage.lastRandomHeroFeaturedIndex = 0;
    }
    this.buildHeroCollection();
  },
  computed: {
    mainClass(){
      return `card card-hero is-shadowless pt-0 ${this.classText} ${this.classBg} card-is-not-rounded`;
    }
  },
};
</script>
