<template>
  <li>
    <div class="is-flex">
      <a href="#" @click="toggleItem($event)" class="font-weight-600" :title="title">
        <i style="margin-left: -5px;" :class="[ !itemsHidden ? 'rotate-quarter icon icon-chevron-right' : 'icon icon-chevron-right' ]" />
      </a>
		  <a :href="link" v-text="title" class="font-weight-600" :class="{ 'has-text-link' : active }" :title="title" />
    </div>
		<ul :class="{ 'is-hidden' : itemsHidden }" class="nav-child">
			<slot />
		</ul>
	</li>
</template>

<script>
export default {
  name: 'CollapseMenuIcon',
  props: {
    title: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    iconClass : {
      type: String,
      default: 'icon icon-chevron-right'
    },
    active: {
      type: Boolean,
      default: false
    },
    toggle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      itemsHidden: true,
    }
  },
  mounted() {
    this.itemsHidden = !this.toggle;
  },
  methods: {
    toggleItem(e){
      e.preventDefault();
      this.itemsHidden = !this.itemsHidden;
    }
  }
}
</script>
