import { render, staticRenderFns } from "./SecondSlidePromoFlexible.vue?vue&type=template&id=24f2ac0e&"
import script from "./SecondSlidePromoFlexible.vue?vue&type=script&lang=js&"
export * from "./SecondSlidePromoFlexible.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports