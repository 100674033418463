<template>
  <div>
    <div class="box mb-5 is-shadowless" :class="classBackground">
      <div class="search-form">
        <label for="search-keyword" class="label">{{ label }}</label>
        <div class="inputSearch">
          <v-select
            v-model="value"
            :loading="loading"
            :filterable="false"
            :placeholder="placeholder"
            :reduce="({ labelOriginal }) => labelOriginal"
            :clearSearchOnBlur="() => false"
            :clearSearchOnSelect="false"
            @option:selected="selectOption"
            @search="fetchOptions"
            :options="dataFiltered"
            label="labelOriginal"
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                ref="input"
                v-bind="{ ...attributes, value: search }"
                v-on="events"
              />
            </template>
            <template #option="{ label }">
              <div v-html="label"></div>
            </template>
            <template #no-options> No result found </template>
            <template #open-indicator="{ attributes }">
              <span :class="icon" v-bind="attributes"></span>
            </template>
          </v-select>
        </div>
      </div>
    </div>
    <div class="columns is-multiline mb-0 contact-media-row">
      <section v-if="noFound" class="column">
        <div
          class="contacts-block contact-media has-background-white has-text-dark"
        >
          <div class="content has-text-centered">
            <strong class="contacts-title">No contact found</strong>
          </div>
        </div>
      </section>
      <section
        v-for="(item, index) in list"
        :key="index"
        class="column is-4-desktop"
      >
        <div
          class="contacts-block contact-media has-background-white has-text-dark"
        >
          <div class="columns is-multiline is-desktop">
            <div class="column">
              <div class="contact-card">
                <a :href="item.url"
                  ><strong class="contacts-title">{{ item.name }}</strong></a
                >
                <ul class="contacts-list list-unstyled">
                  <li>
                    <i class="icon icon-phone"></i>
                    <a :href="'tel:' + item.phone.replaceAll('-', '')">{{
                      item.phone
                    }}</a>
                  </li>
                  <li>
                    <i class="icon icon-mail"></i>
                    <a :href="'mailto:' + item.email">Email</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "FilterMediaContact",
  components: { vSelect },
  props: {
    urlEndpoint: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Search",
    },
    icon: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    classBackground: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      search: "",
      options: [],
      list: [],
      loading: false,
      noFound: false,
      value: null,
      debounceTimer: null,
    };
  },
  watch: {
    value(a) {
      if (!a) {       
        this.fetchOptions("");
        this.list = [];
        this.$el.querySelector(".vs__clear").classList.remove("!block");
      }else {
        this.$el.querySelector(".vs__clear").classList.add("!block");
      }
    },
  },
  computed: {
    dataFiltered() {
      let filter = this.options.filter((el) =>
        el.label.toLowerCase().includes(this.search.toLowerCase())
      );
      filter = filter.map((el) => {
        const regex = new RegExp(this.search, "gi");
        return {
          url: el.url,
          labelOriginal: el.label,
          label: el.label.replace(regex, (str) => {
            return `<span class="text-blue">${str}</span>`;
          }),
        };
      });

      filter.sort((a, b) => a.label.localeCompare(b.label));
      return filter;
    },
  },
  mounted() {
    this.fetchOptions("");
  },
  methods: {
    onCloseToggle() {
      if (!this.overToggle) this.focusToggle = false;
    },
    async handleAnchorClick(e, url, label) {
      if (e) e.preventDefault();

      this.inputValue = label;
      this.focusToggle = false;
      const response = await axios.get(`${url}`);
      this.list = response.data.contacts;
      if (this.list.length > 0) {
        this.hiddenNotFound = true;
      } else {
        this.hiddenNotFound = false;
      }
    },
    fetchOptions(search) {
      this.search = search;
      this.loading = true;
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.value = search || null;
        axios
          .get(this.urlEndpoint, { params: { search } })
          .then((response) => {
            this.options = response.data.contactRoles;
            if (!response.data.contactRoles.length) {
              this.noFound = true;
              this.list = [];
            } else {
              this.noFound = false;
            }
          })
          .catch(() => {
            this.options = [];
          })
          .finally(() => {
            this.loading = false;
          });
      }, 200);
    },
    async selectOption(option) {
      this.search = option.labelOriginal;
      const response = await axios.get(`${option.url}`);
      this.list = response.data.contacts;
    },
  },
};
</script>
