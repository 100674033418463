<template>
  <ul :class="upperClass">
    <li
      v-for="(button, index) in buttons"
      :key="`button-${index}`"
    >
      <a
        :href="button.link"
        class="button is-large is-primary is-light"
        :ref="`button-${index}`"
      >
        {{ button.abbreviation }}
      </a>
      <a :href="button.link" @mouseover="applyHoverEffect(index)" @mouseout="removeHoverEffect(index)" >
        <span class="desc has-text-primary">{{ button.text }}</span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "ButtonContentNavigator",
  props: {
    upperClass: {
      type: String,
      required: true,
    },
    buttons: {
      type: Array,
      required: true,
    },
  },
  methods: {
    applyHoverEffect(id){
      this.$refs[`button-${id}`][0].classList += 'action-hover-navigation';
    },
    removeHoverEffect(id){
      this.$refs[`button-${id}`][0].classList = 'button is-large is-primary is-light';
    }
  },
};
</script>
