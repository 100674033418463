<template>
  <div>
    <div class="filter-block" :class="{ 'is-open-close-active': isOpenNav }">
      <h5 class="has-text-grey-darker">{{ title }}</h5>
      <template v-for="(option, index) in visibleOptions">
        <label
          class="custom-control custom-checkbox"
          :key="`visible-opt-${index}`"
        >
          <input
            type="checkbox"
            class="custom-control-input"
            :name="`${inputName}[]`"
            :id="`${inputName}-${option.id}`"
            @change="handleChange($event)"
            :value="option.value"
            :checked="checkboxFilters.filters[inputName] && checkboxFilters.filters[inputName].includes(option.value.toString())"
          />
          <span class="custom-control-indicator"></span>
          <span class="custom-control-label" v-html="option.title"></span>
        </label>
      </template>
      <div v-if="dropDownOptions.length > 0" class="slide" :class="{ 'is-hidden': !isOpenNav }">
        <template v-for="(option, index) in dropDownOptions">
          <label
            class="custom-control custom-checkbox"
            :key="`drop-down-opt-${index}`"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              :name="`${inputName}[]`"
              :id="`${inputName}-${option.id}`"
              @change="handleChange($event)"
              :value="option.value"
              :checked="checkboxFilters.filters[inputName] && checkboxFilters.filters[inputName].includes(option.value.toString())"
            />
            <span class="custom-control-indicator"></span>
            <span class="custom-control-label" v-html="option.title"></span>
          </label>
        </template>
      </div>
      <div v-if="dropDownOptions.length > 0" class="btn-opener-holder">
        <a href="#" class="open-close-opener" @click="toggleFilter($event)">
          <span>{{ navigationText }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FilterBlock",
  props: {
    title: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: false,
      default: "",
    },
    visibleOptions: {
      type: Array,
      required: true,
    },
    dropDownOptions: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      isOpenNav: false,
    };
  },
  computed: {
    ...mapGetters({
      checkboxFilters: "getSearchFilters",
    }),
    navigationText() {
      return this.isOpenNav ? "Hide" : "See all";
    },
  },
  methods: {
    toggleFilter(e) {
      e.preventDefault();
      this.isOpenNav = !this.isOpenNav;
    },
    handleChange: function (e) {
      const name = e.target.name.replace('[]', '');
      const value = e.target.value;

      const filters = { ...this.checkboxFilters.filters };

      if (filters[name] == undefined) {
        filters[name] = new Array();
      }

      if (filters[name].includes(value)) {
        filters[name] = filters[name].filter((c) => {
          return c !== value;
        });
      } else {
        filters[name].push(value);
      }
      
      this.$store.dispatch('assignGeneralSearchFilters', { filters });
      this.$store.dispatch("fetchGeneralSearch");
    },
  },
};
</script>
