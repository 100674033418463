<template>
  <div>
    <iframe
      width="100%"
      height="450"
      :src="urlVideo"
      frameborder="0"
      :allow="allowVideoOptions"
      allowfullscreen
    ></iframe>
  </div>
</template>
methods: {
  },
<script>
export default {
  name: 'VideoEmbed',
  props: {
    videoId: {
      type: String,
      required: true,
    },
    isYoutubeVideo: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    urlVideo() {
      return this.isYoutubeVideo
        ? `https://www.youtube.com/embed/${this.videoId}`
        : `https://player.vimeo.com/video/${this.videoId}`;
    },
    allowVideoOptions() {
      return this.isYoutubeVideo
        ? 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        : 'autoplay; fullscreen; picture-in-picture';
    },
  },
};
</script>
