<template>
  <div v-if="config == 'default'">
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    config: {
      type: String,
      default: 'default'
    }
  }
}
</script>
