<template>
  <div>
    <ul class="members-list items-list list-unstyled has-text-info has-text-weight-semibold">
      <li v-for="(member, index) in memberList" :key="index">
        <a :href="member.URL" target="_blank" v-if="member.URL && member.URL !== ''">
          {{ member.Company }}
        </a>
        <span v-else>{{ member.Company }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'MemberList',
  props: {
    urlEndpoint: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      memberList: []
    }
  },
  mounted() {
    this.fetchMemberList();
  },
  methods: {
    async fetchMemberList() {
      try {
        const jsonData = (await axios.get(this.urlEndpoint)).data;
        this.memberList = jsonData.Companies;
      } catch (error) {
        this.memberList = [ { Company: 'An unexpected error has occurred', URL: '/' } ]
      }
    }
  },
}
</script>
ss