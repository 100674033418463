<template>
  <div class="slideshow">
    <div ref="main" class="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in list" :key="`image-${_uid}-${index}`">
          <figure class="gallery-image">
            <img :src="item.img" :alt="item.alt">
            <span v-if="item.description" class="gallery-image-description">
              {{ item.description }}
            </span>
            <figcaption v-if="item.by" class="gallery-image-caption">
              Photo: {{ item.by }}
            </figcaption>
          </figure>
        </div>
      </div>
      <div class="swiper-button-prev swiper-arrows" ref="main-prev"></div>
      <div class="swiper-button-next swiper-arrows" ref="main-next"></div>
    </div>
    <div thumbsSlider="" ref="thumb" class="swiper thumbnails">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in list" :key="`image-${_uid}-${index}`">
          <figure class="gallery-image">
            <img :src="item.img" :alt="item.alt">
          </figure>
        </div>
      </div>
      <div class="swiper-button-prev swiper-arrows" ref="thumb-prev"></div>
      <div class="swiper-button-next swiper-arrows" ref="thumb-next"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import { Thumbs, Navigation } from 'swiper/modules';

export default {
  name: "Slideshow",
  props: {
    list: {
      type: Array,
      default: []
    },
    columns: {
      type:String,
      default: "is-12-desktop"
    }
  },
  data() {
    return {
      swiper: null,
      thumb: null,
    }
  },
  computed: {
    perView(){
      switch(this.columns){
        case "is-4-desktop":
          return {
            spaceBetween: 10,
            slidesPerView: 4,
            slidesPerGroup: 4
          }
        case "is-6-desktop":
        case "is-8-desktop":
          return {
            spaceBetween: 20,
            slidesPerView: 5,
            slidesPerGroup: 5
          }
        default:
          return {
            spaceBetween: 30,
            slidesPerView: 6,
            slidesPerGroup: 6
          }
      }
    }
  },
  mounted() {
    this.thumb = new Swiper(this.$refs["thumb"], {
      modules: [Navigation],
      speed: 450,
      navigation: {
        prevEl: this.$refs["thumb-prev"],
        nextEl: this.$refs["thumb-next"]
      },
      centerInsufficientSlides: true,
      spaceBetween: 10,
      slidesPerView: 4,
      slidesPerGroup: 4,
      breakpoints: {
        768: this.perView
      },
      freeMode: true,
      watchSlidesProgress: true
    });

    this.swiper = new Swiper(this.$refs["main"], {
      modules: [Thumbs, Navigation],
      speed: 450,
      navigation: {
        prevEl: this.$refs["main-prev"],
        nextEl: this.$refs["main-next"]
      },
      thumbs: {
        swiper: this.thumb
      }
      
    });
  }
}
</script>