<template>
  <article class="news-article mt-5">
    <div class="content">
      <h4>
        <a :href="article.url" v-html="article.title"></a>
      </h4>
      <p>
        {{ article.description }}
      </p>

      <div v-if="article.tags" class="tags">
        <a 
          v-for="(tag, index) in article.tags" 
          :key="`tag-${index}`" 
          :href="tag.url" 
          class="tag button is-primary is-outlined is-uppercase"
        >{{ tag.name }}</a>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'ArticleItem',
  props: {
    article: {
      type: Object,
      required: true
    }
  }
};
</script>
