<template>
  <transition name="fade">
    <div class="form-card" v-show="show" v-if="cookie">
      <div class="form-card-wrapper" :class="{ open: open }">
        <div class="form-card-title-wrapper">
          <div class="form-card-title">
            {{ title }}
          </div>
          <button aria-label="close" class="form-card-icon" @click="onToggle">
            <i v-if="success" v-icon:xmark class="arrow"></i>
            <i v-else v-icon:head-arrow class="arrow"></i>
            <i v-icon:clipboard></i>
          </button>
        </div>
        <div class="form-card-content-wrapper" :class="{ loading }">
          <div class="form-card-content">
            <template v-if="!success">
              <slot></slot>
            </template>
          </div>
          <span class="overlay">
          </span>
          <span class="spin"></span>
          <div class="message" :class="{ open: message, error, success }" @click="message = false">
            {{ success ? success : 'An unexpected error occurred. Try again later.' }}
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import fixedMixin from "../../utils/fixedMixin"
export default {
  name: "VFormCard",
  mixins: [fixedMixin],
  props: {
    title: String,
    description: String
  },
  data() {
    return {
      open: false,
      loading: false,
      error: false,
      success: null,
      message: false,
      cookie: true,
      show: false,
    }
  },
  mounted() {
    const $form = this.$el.querySelector('form');

    if (!this.getCookie($form.name)) {
      this.setFixedArea(this.$el, null);
      this.show = true;
      window.addEventListener("load", () => {
        setTimeout(() => {
          this.open = true;
        }, 600)
      });

      if (this.cookie)
        this.onSubmitForm();
    } else {
      this.cookie = false;
    }
  },
  methods: {
    onToggle(){
      if(this.success){
        this.show = false;
        setTimeout(() => {
          this.cookie = false;
        }, 2000)
      }else {
        this.open = !this.open
      }

    },
    onSubmitForm() {
      const $form = this.$el.querySelector('form');
      if ($form) {
        const url = $form.dataset.action;
        $form.addEventListener("submit", (e) => {
          e.preventDefault();
          const body = new FormData($form);
          (async () => {
            this.loading = true;
            const res = await fetch(url, { method: 'POST', body })
              .then(async (r) => {
                const text = await r.text();
                return { status: r.status, text }
              });

            switch (res.status) {
              case 200:
                this.setCookie($form.name, 365);
                this.success = res.text.replace(/\"/gi, "");
                setTimeout(() => {
                  this.show = false;
                }, 5000)
                setTimeout(() => {
                  this.cookie = false;
                }, 5600)
                break;
              default:
                this.error = true;
            }
            this.loading = false;
            this.message = true;
            setTimeout(() => {
              this.message = false;
            }, 5000)
          })();

        })
      }
    },
    setCookie(cname, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=;" + expires + ";path=/";
    },
    getCookie(cname) {
      const cookie = decodeURIComponent(document.cookie).split(";").find(stg => stg.includes(cname));
      return cookie;
    }
  }
}
</script>