<template>
  <div class="my-1">
    <form action="#" class="search-form bg-white">
      <div class="control">
        <input
          type="text"
          class="input"
          placeholder="Type your ZIP or state"
          id="search-in-acc"
          v-model="needle"
          autocomplete="off"
        />
        <button type="submit" class="btn-search" @click="checkEndpoint($event)">
          <i class="icon icon-search-thick"></i>
          <span class="is-sr-only">Search</span>
        </button>
      </div>
      <p :class="classResponse">{{ messageResponse }}</p>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "StateLookup",
  props: {
    urlEndPoint: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      needle: "",
      errorOnRequest: false,
      messageResponse: '',
      classResponse: '',
      autoCompleteFunction: 'on'
    };
  },
  methods: {
    async checkEndpoint(e) {
      e.preventDefault();
      this.classResponse = 'help is-success';
      this.messageResponse = 'Searching for your page ...';
      try {
        const response = await axios.get(`${this.urlEndPoint}?needle=${this.needle}`);
        if (response.data.state_code && parseInt(response.data.state_code) === 200) {
          this.classResponse = 'help is-success';
          this.messageResponse = 'Redirecting...';
          setTimeout(() => {
            window.location.href = response.data.page_url;
          }, 2000);
        }
        else {
          this.errorOnRequest = true;
          this.classResponse = 'help is-danger';
          this.messageResponse = 'No page found';
        }
      } catch (error) {
        this.errorOnRequest = true;
        this.classResponse = 'help is-danger';
        this.messageResponse = 'An unexpected error has occurred';
      }
    }
  },
};
</script>
