<template>
  <nav class="add-nav" :class="{ open: open() == index }">
    <h6 class="has-text-weight-semibold">
      <slot></slot>
      <span class="dropdown-icon" v-icon:chevron-down @click="onToggle"></span>
    </h6>
    <div class="add-nav-content">
      <div class="add-nav-wrapper">
        <slot name="content"></slot>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "VMegamenu",
  props: {
    index: {
      type: Number,
      require: true,
    },
  },
  inject: ["onOpen", "open"],
  methods: {
    onToggle() {
      this.onOpen(this.index);
    },
  },
};
</script>
