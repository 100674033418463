<template>
  <div>
    <section
      class="slide pt-0"
      :class="{ 'is-hidden': !item.makeVisible }"
      v-for="(item, index) in heroCollection"
      :key="`hero-${index}`"
    >
      <div
        class="hero-body"
        :class="[
          item.textBlockToLeft ? 'is-left-alignment' : 'is-right-alignment',
        ]"
      >
        <div class="hero-bg hide-for-print" :style="setBgHero(item.image)"></div>
        <div class="container">
          <div class="columns">
            <div class="column is-7-tablet is-6-desktop">
              <div class="card" :class="[classBg, classText]">
                <div
                  class="card-content remove-shadow-for-print"
                  :class="cursorClass"
                  @click="goToLink(item.link.link)"
                  @mouseover="changeCursorCtaMode()"
                >
                  <div class="content">
                    <h1 :class="classText" v-html="item.title"></h1>
                    <p :class="classText">{{ item.description }}</p>
                  </div>
                  <a
                    :href="item.link.link"
                    class="button is-medium is-link is-rounded"
                  >
                    <span>{{ item.link.text }} </span>
                    <span class="icon is-large">
                      <i class="icon icon-arrow-right"></i></span
                  ></a>
                  <template v-if="item.photoCredit">
                    <div v-if="item.photoCredit.length > 0">
                      <div class="is-pulled-right">
                        <span class="made-by hide-for-print" v-html="item.photoCredit" />
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "HeroManager",
  props: {
    heroOptions: {
      type: Array,
      required: true,
    },
    classText: {
      type: String,
      required: true,
    },
    classBg: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cursorClass: "",
      heroCollection: [],
    };
  },
  methods: {
    setBgHero(image) {
      return `background-image: url(${image})`;
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    },
    goToLink(link) {
      location.href = link;
    },
    changeCursorCtaMode() {
      this.cursorClass = "cursor-pointer";
    },
    buildHeroCollection() {
      let randomHeroIndex;
      if (this.heroOptions.length > 1) {
        do {
          randomHeroIndex = this.getRandomInt(this.heroOptions.length);
        } while (
          parseInt(localStorage.lastRandomHeroIndex) ===
          parseInt(randomHeroIndex)
        );
        localStorage.lastRandomHeroIndex = randomHeroIndex;
      } else {
        randomHeroIndex = 0;
      }
      this.heroCollection = this.heroOptions.map((hero, index) => ({
        ...hero,
        makeVisible: index === randomHeroIndex,
      }));
    }
  },
  mounted() {
    if (!localStorage.lastRandomHeroIndex) {
      localStorage.lastRandomHeroIndex = 0;
    }
    this.buildHeroCollection();
  }
};
</script>
