var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-select"},[_c('v-select',{attrs:{"searchable":false,"appendToBody":true,"options":_vm.options,"placeholder":_vm.placeholder,"label":"name","reduce":function (ref) {
	var value = ref.value;

	return value;
},"value":_vm.value},on:{"input":_vm.onChange},scopedSlots:_vm._u([{key:"open-indicator",fn:function(){return [_c('span',{staticClass:"vs__arrow"})]},proxy:true},{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search"},'input',attributes,false),events)),_vm._v(" "),_c('input',{attrs:{"type":"hidden","name":_vm.name},domProps:{"value":_vm.v}})]}},{key:"option",fn:function(ref){
var name = ref.name;
return [_c('div',{staticClass:"vs__item_option"},[_c('span',{staticClass:"vs__item_option_label"},[_vm._v(_vm._s(name))])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }