<template>
  <div v-cloak>
      <div v-if="showPostback && postbackMessage" class="container px-3">
        <div v-html="postbackMessage" class="mb-3" />
        <div class="is-flex is-justify-content-flex-end is-flex-grow-1">
          <button class="button is-link is-rounded has-text-white" @click="handleCloseBtn">Close</button>
        </div>
      </div>
      <div v-formrest v-show="!showPostback">
        <slot></slot>
      </div>
  </div>
</template>

<script>
export default {
  directives: {
    formrest: {
      // Definición de directiva
      inserted (el, binding, { context }) {

        const form = el.querySelector('[data-form-block]')

        if (!form) return

        form.addEventListener('submit', async (event) => {
          event.preventDefault()

          const url = form.dataset.action
          const captchaAction = form.querySelector('.captcha_reload')

          try {
            const res = await context.onSubmitForm(form, url)
            context.onSuccessSubmit(res)

            context.cleanErrors(form)
            form.reset()
            if (captchaAction) captchaAction.click()


          } catch (errors) {

            context.cleanErrors(form)
            errors.forEach(({ fieldName, message }) => {
              const inputs = form.querySelectorAll(`[name*="[${fieldName}]"]`)
              const input = Array.from(inputs).pop()

              const newNode = context.createNodeError(message)
              context.insertAfter(input, newNode)
            })

            if (captchaAction) captchaAction.click()
          }
        })

      }
    }
  },
  props: {
    urlredirect: {
      type: String,
      default: '',
    },
    postbackMessage: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      showPostback: false
    }
  },
  methods: {
    onSuccessSubmit (res) {
      if (this.urlredirect) {
        window.open(this.urlredirect, "_blank")
      }
      if (this.postbackMessage) {
        this.showPostback = !this.showPostback
      } else {
        this.$emit('success-submit')
      }
    },
    async onSubmitForm(form, url) {

      if(typeof reloadTokens !== "undefined") await reloadTokens(); 
      
      const body = new FormData(form)
      const response = await fetch(url, { method: 'post', body })
      const data = await response.json()


      if (!response.ok) throw data

      return data
    },
    insertAfter(referenceNode, newNode) {
      referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    },
    createNodeError(message) {
      const newNode = document.createElement('p')
      newNode.classList.add('help')
      newNode.classList.add('is-danger')
      newNode.classList.add('mb-2')
      newNode.classList.add('is-handle-manual-error')
      newNode.appendChild(document.createTextNode(message))
      return newNode
    },
    async cleanErrors(form) {
      const nodeErrors = await form.querySelectorAll('.is-handle-manual-error')
      Array.from(nodeErrors).forEach(err => err.remove())
    },
    handleCloseBtn() {
      this.$emit('success-submit');
      this.showPostback = false;
    }
  }
}
</script>
<style scoped>
  .ezstring-field {
    display: none;
  }
</style>
