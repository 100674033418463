<template>
  <div>
    <slot :onToggle="onToggle" :open="open"></slot>
  </div>
</template>

<script>
export default {
  name: "VHeader",
  data() {
    return {
      current: null,
      open: false
    }
  },
  provide() {
    return {
      onOpen: this.onCurrent,
      open: () => {
        return this.current
      }
    };
  },
  methods: {
    onCurrent(i) {
      this.current = i == this.current ? null : i;
    },
    onToggle() {
      this.open = !this.open;
      this.current = null;
    }
  }
};
</script>

<style lang="scss" scoped></style>
