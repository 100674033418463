<template>
  <div class="news-cards-container">
    <section
      v-for="(item, index) in news"
      class="card card-news is-horizontal is-small is-shadowless p-0"
      :key="`news-${index}`"
    >
      <div class="card-content">
        <div class="content">
          <div class="meta">
            <span class="category-label is-uppercase has-text-primary">
              <a href="#">EQUINOX</a>
            </span>
            <time class="date" :datetime="item.shortDate">{{item.longDate}}</time>
          </div>
          <h5 class="has-text-weight-medium">
            <a :href="item.link" target="blank" v-html="item.text" />
          </h5>
        </div>
      </div>
    </section>
    <section
      v-if="this.errorOrEmptyNews"
      class="card card-news is-horizontal is-small is-shadowless"
      :key="`news-1`"
    >
      <div class="card-content">
        <div class="content pt-4 pb-4">
          <div class="meta">
            <span class="category-label is-uppercase has-text-primary">
              <a href="#">EQUINOX</a>
            </span>
            <span>Newsletter empty, please reload the page for more news.</span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "SmartBriefNews",
  props: {
    urlXml: {
      type: String,
      required: true,
    },
    limit: {
      type: String,
      required: true
    },
    offset: {
      type: String,
      required: false,
      default: '0'
    },
    forceShowEmptyNews: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      news: [],
      errorOrEmptyNews: false
    }
  },
  mounted() {
    this.fetchRssData();
  },
  methods: {
    async fetchRssData(){
      if (this.forceShowEmptyNews) {
        this.errorOrEmptyNews = true;
        return;
      }

      try {
        const containerData = await fetch(this.urlXml);
        const xml = await containerData.text();
        let xmlDom = (new DOMParser()).parseFromString(xml, 'application/xml');
        let newsSelection = xmlDom.querySelectorAll('item');
        newsSelection = Object.values(newsSelection);
        newsSelection = newsSelection.filter((itemNews, index) =>
          ( index >= this.offset &&  index < ( parseInt(this.limit) + parseInt(this.offset)))
        );
        let shortDate, longDate;
        newsSelection.forEach(itemNews => {
          [ shortDate, longDate ] = this.getDateVariations(itemNews.children[4].innerHTML);
          this.news.push({
            text: itemNews.children[0].innerHTML,
            link: itemNews.children[2].innerHTML,
            shortDate: shortDate,
            longDate: longDate
          });
        });
        if (!newsSelection || newsSelection.length === 0) {
          this.errorOrEmptyNews = true;
        }
      } catch (error) {
        this.errorOrEmptyNews = true;
      }
    },
    getDateVariations(extensiveDate){
      let date = new Date(extensiveDate);
      let day, month, year, longMonth;
      [day, month, year, longMonth] = [
          date.getDate(),
          `${date.getMonth()+1}`,
          date.getFullYear(),
          date.toLocaleString('default', { month: 'long' })
      ];
      return [
        `${year}-${month}-${day}`,
        `${longMonth} ${day}, ${year}`
      ];
    }
  }
}
</script>
