<template>
  <transition name="fade-notification" v-cloak>
    <div
      class="notification full-width is-link is-radiusless mb-0"
      v-if="showNotification"
    >
      <div class="container">
        <button class="delete" @click="close()">
            <span class="is-sr-only">Delete Notifications</span>
          </button>
          <slot />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'HeaderNotification',
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      showNotification: false
    }
  },
  computed: {
    cookieName() {
      return `acc-notification-${this.id}`
    }
  },
  methods: {
    close(){
      this.showNotification = false;

      if (this.id) {
        const expiration = new Date();
        expiration.setFullYear(expiration.getFullYear() + 1);

        this.$setCookie(this.cookieName, '', expiration);
      }

      //  Wait for animation before removing
      setTimeout(() => {
        this.$destroy();
        this.$el.remove();
      }, 250);
    }
  },
  mounted() {
    if (!this.id) {
      console.warn('No Id supplied for notification');
      return;
    }

    if (this.$getCookie(this.cookieName) !== null) {
      this.close();
    } else {
      this.showNotification = true;
    }
  }
}
</script>
