<template>
  <li class="main-menu-item" :class="{ open: open() == index }" @mouseover="onOverflow('clip')" @mouseout="onOverflow('')">
    <span class="main-menu-item-wrapper" ref="link" :class="{'is-active':isActive}">
      <slot></slot>
      <span class="dropdown-icon" v-icon:chevron-down @click="onToggle"></span>
    </span>
    <div class="main-menu-dropdown-megamenu">
      <div class="container">
        <div class="main-menu-dropdown-megamenu-wrapper">
          <slot name="megamenu"></slot>
        </div>
      </div>
      <div class="overlay"></div>
    </div>
  </li>
</template>

<script>
export default {
  name: "VMegamenu",
  props: {
    index: {
      type: Number,
      require: true,
    },
  },
  inject: ["onOpen", "open"],
  data() {
    return {
      isActive: false,
    };
  },
  mounted() {
    const $link = this.$refs["link"].querySelector("a").href.split("#");
    this.isActive = $link.includes(window.location.href);
    
  },
  methods: {
    onToggle() {
      this.onOpen(this.index);
    },
    onOverflow(n) {
      document.querySelector("html").style.overflow = n;
    },
  },
};
</script>

<style lang="scss" scoped></style>
