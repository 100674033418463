<template>
  <div class="column is-full">
    <section class="card card-news is-horizontal is-shadowless px-0">
      <div class="card-image" v-if="article.image && article.image.length > 0">
        <figure class="image mx-0">
          <img loading="lazy" :src="article.image" :alt="article.title" />
        </figure>
      </div>

      <div class="card-content">
        <div class="content">
          <div class="meta">
            <span class="category-label is-uppercase has-text-primary">
              <a :href="article.url" v-html="article.title"></a>
            </span>
            <time
              v-if="contentTypesWithDates.indexOf(article.content_type) > -1"
              class="date"
              :datetime="article.publish_date.ymd"
            >
              {{ article.publish_date.mdy }}
            </time>
          </div>
          <h5>
            <a :href="article.url" v-html="article.title"></a>
          </h5>
          <p v-html="article.description"></p>
          <div class="tags" v-if="article.tags">
            <a
              v-for="(tag, index) in article.tags"
              :key="`tag-${index}`"
              :href="tag.url"
              class="tag button is-primary is-outlined is-uppercase"
            >
              {{ tag.name }}
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ArticleItem",
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contentTypesWithDates: [
        'article',
        'press_release',
        'resource'
      ]
    }
  }
};
</script>
