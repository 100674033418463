var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inputSearch"},[_c('v-select',{attrs:{"reduce":function (ref) {
	var labelOriginal = ref.labelOriginal;

	return labelOriginal;
},"clearSearchOnBlur":function () { return false; },"clearSearchOnSelect":false,"options":_vm.dataFiltered,"label":"label"},on:{"option:selected":_vm.selectOption,"search":function (res) { return _vm.search = res; }},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search"},'input',Object.assign({}, attributes, {value: _vm.search}),false),events))]}},{key:"option",fn:function(ref){
var label = ref.label;
var url = ref.url;
return [_c('span',{domProps:{"innerHTML":_vm._s(label)}})]}},{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({class:_vm.icon},'span',attributes,false))]}}]),model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }