import directives from './directives';
import cookies from './cookies';

export default {
    install: (Vue, options) => {

        for (let dir in directives) {
            if (directives.hasOwnProperty(dir)) {
                Vue.directive(dir, directives[dir])
            }
        }

        for (let func in cookies) {
            if (cookies.hasOwnProperty(func)) {
                Vue.prototype[`$${func}`] = cookies[func];
            }
        }
    }
}