<template>
  <div class="content-section" :class="mainClass">
    <h4 v-if="title.length > 0">{{ title }}</h4>
    <div
      v-if="hasWrappingContent"
      class="side-column"
      :class="[wrappingContentToRight ? 'is-pulled-right' : 'is-pulled-left']"
      v-html="wrappingContent"
    />
    <div v-html="htmlContent" />
  </div>
</template>

<script>
export default {
  name: "Paragraph",
  props: {
    mainClass: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    htmlContent: {
      type: String,
      default: "",
    },
    hasWrappingContent: {
      type: Boolean,
      default: false,
    },
    wrappingContent: {
      type: String,
      default: "",
    },
    wrappingContentToRight: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
