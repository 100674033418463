<template>
  <div class="aside-open-close" :class="{ 'is-open-close-active': isOpenNav }">
    <div class="content">
      <ul class="navigation-list-feature has-text-info mb-0 pb-2">
        <template v-for="(item, index) in visibleList">
          <template v-if="item.submenu.length === 0">
            <li :key="`item-${index}`" class="simple-nav-item font-weight-600">
              <a :href="item.link" :class="{ 'has-text-link': item.active }">{{
                item.text
              }}</a>
            </li>
          </template>
          <template v-else>
            <collapse-menu-icon
              :key="`item-${index}`"
              :title="item.text"
              :link="item.link"
            >
              <li
                v-for="(subItem, subIndex) in item.submenu"
                :key="`sub-nav${subIndex}-${index}`"
              >
                <a
                  :href="subItem.link"
                  :class="{ 'has-text-link': item.active }"
                  >{{ subItem.text }}</a
                >
              </li>
            </collapse-menu-icon>
          </template>
        </template>
      </ul>
      <ul class="navigation-list-feature has-text-info" :class="{ 'is-hidden': !isOpenNav }">
        <template v-for="(item, index) in expandibleList">
          <template v-if="item.submenu.length === 0">
            <li :key="`expand-item${index}`" class="simple-nav-item font-weight-600">
              <a :href="item.link" :class="{ 'has-text-link': item.active }">{{
                item.text
              }}</a>
            </li>
          </template>
          <template v-else>
            <collapse-menu-icon
              :key="`expand-item-${index}`"
              :title="item.text"
              :link="item.link"
            >
              <li
                v-for="(subItem, subIndex) in item.submenu"
                :key="`sub-nav${subIndex}-${index}`"
              >
                <a
                  :href="subItem.link"
                  :class="{ 'has-text-link': subItem.active }"
                  >{{ subItem.text }}</a
                >
              </li>
            </collapse-menu-icon>
          </template>
        </template>
      </ul>
    </div>
    <div v-if="expandibleList.length > 0" class="btn-opener-holder" >
      <a
        href="#"
        class="open-close-opener"
        :class="{ 'mt-2': isOpenNav }"
        @click="toggleNav($event)"
      >
        <span class="default-text">{{ seeMoreText }}</span>
        <span class="hidden-text">{{ HideText }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import CollapseMenuIcon from "../collapse/CollapseMenuIcon.vue";
export default {
  components: { CollapseMenuIcon },
  name: "OpenCloseWithSubNav",
  props: {
    visibleList: {
      type: Array,
      required: true,
    },
    expandibleList: {
      type: Array,
      required: true,
    },
    seeMoreText: {
      type: String,
      required: false,
      default: "See all",
    },
    HideText: {
      type: String,
      required: false,
      default: "Hide",
    },
  },
  data() {
    return {
      isOpenNav: false,
    };
  },
  methods: {
    toggleNav(e) {
      e.preventDefault();
      this.isOpenNav = !this.isOpenNav;
    },
  },
};
</script>
