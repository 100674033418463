<template>
  <div>
    <section class="card card-news is-horizontal is-shadowless promo-person">
      <div class="card-image">
        <figure class="image">
          <a href="#">
            <img loading="lazy" :src="profileImage" alt="image description" />
          </a>
        </figure>
      </div>
      <div class="card-content">
        <div class="content">
          <slot />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "PromoPerson",
  props: {
    image: String,
    required: false,
    default: "",
  },
  computed: {
    profileImage() {
      return this.image.length === 0
        ? "https://americanchemistrycouncil.gitlab.io/ACCWeb/Frontend/images/generic-avatar.svg"
        : this.image;
    },
  },
};
</script>
